import {
  ComponentRef,
  inject,
  Injectable,
  OnDestroy,
  Type,
} from '@angular/core';
import { ComponentMakerService } from '@compass/ui';
import { DebugOutletComponent } from '@compass/environment';
import { Logger } from '@compass/logging';
import { Subscription } from 'rxjs';
import { Point } from '@angular/cdk/drag-drop';
import { EphemeralStorageService } from '@compass/storage';

/**
 * The key used to store the debug mode flag in storage.
 * @type {string}
 */
const DEBUG_STORAGE_KEY = '__debug_mode__';

/**
 * Represents a debug storage entity.
 *
 * @interface DebugStorageEntity
 */
interface DebugStorageEntity {
  windowLocation?: Point;
  windowsVisible?: boolean;
  userData?: { [key: string]: unknown };
}

/**
 * DebugService class handles enabling and disabling debug mode, as well as managing the debug window.
 * @implements {OnDestroy}
 * @injectable
 */
@Injectable()
export class DebugService implements OnDestroy {
  private readonly _componentMaker = inject(ComponentMakerService);
  private readonly _logger = inject(Logger);
  private readonly _ephemeralStorage = inject(EphemeralStorageService);

  private _positionUpdatedSub?: Subscription;
  private _enabled: boolean = false;
  private _debugComponentRef?: ComponentRef<DebugOutletComponent>;
  private _userData?: { [key: string]: unknown };

  get enabled(): boolean {
    return this._enabled;
  }

  get windowVisible(): boolean {
    return this._debugComponentRef?.instance.visible ?? false;
  }

  constructor(private readonly _debugComponent: Type<unknown>) {}

  ngOnDestroy(): void {
    this._positionUpdatedSub?.unsubscribe();
  }

  /**
   * Enables debug mode.
   *
   * @returns {void}
   */
  enable(): void {
    try {
      const settings = this.loadFromStorageOrDefault();
      const ref = this._componentMaker.createInDom(
        DebugOutletComponent,
        {
          debugComponent: this._debugComponent,
          initialConfig: {
            windowVisible: settings.windowsVisible,
            position: settings.windowLocation,
          },
        },
        document.body,
      );

      this._positionUpdatedSub = ref.instance.stateUpdated.subscribe(() => {
        this.persist();
      });

      // Load user data
      this._userData = settings.userData;

      this._debugComponentRef = ref;
      this._enabled = true;

      this._ephemeralStorage.store('debug', this);

      this._logger.info('Debug mode was enabled.');
    } catch (error) {
      this._logger.error('Failed to enable debug mode.', error);
    }
  }

  /**
   * Disables the debug mode.
   *
   * @param {boolean} clear - Clears any debug storage data. Default is true.
   * @return {void}
   */
  disable(clear: boolean = true): void {
    if (!this.enabled) return;

    this._enabled = false;
    this._debugComponentRef?.destroy();

    this._ephemeralStorage.delete('debug');

    if (clear) {
      localStorage.removeItem(DEBUG_STORAGE_KEY);
    }

    this._logger.info('Debug mode was disabled.');
  }

  /**
   * Show the debug window.
   *
   * @returns {void}
   */
  showDebugWindow(): void {
    if (!this._debugComponentRef) return;

    this._debugComponentRef.instance.show();
  }

  /**
   * Hides the debug window if it is currently shown.
   *
   * @returns {void}
   */
  hideDebugWindow(): void {
    if (!this._debugComponentRef) return;

    this._debugComponentRef.instance.hide();
  }

  /**
   * Stores data in the user's storage.
   *
   * @param {string} key - The key used to identify the stored data.
   * @param {unknown} data - The data to be stored.
   * @return {void}
   */
  storeData(key: string, data: unknown): void {
    if (!this.enabled) return;

    this._userData = {
      ...this._userData,
      [key]: data,
    };

    this.persist();
  }

  /**
   * Retrieves data from the user data object.
   * @param {string} key - The key used to retrieve the data.
   * @return {T | undefined} - The retrieved data or undefined if the feature is disabled or the data does not exist for the given key.
   */
  getData<T = unknown>(key: string): T | undefined {
    if (!this.enabled) return undefined;

    return this._userData?.[key] as T;
  }

  private persist(): void {
    const entity: DebugStorageEntity = {
      windowsVisible: this._debugComponentRef?.instance.visible,
      windowLocation: this._debugComponentRef?.instance.currentPosition,
      userData: this._userData,
    };

    localStorage.setItem(DEBUG_STORAGE_KEY, JSON.stringify(entity));
  }

  private loadFromStorageOrDefault(): DebugStorageEntity {
    const storageItem = localStorage.getItem(DEBUG_STORAGE_KEY);
    let jsonObject: Partial<DebugStorageEntity> = {};

    try {
      if (storageItem) {
        jsonObject = JSON.parse(storageItem);
      }
    } catch (error) {
      this._logger.error(
        'Failed to parse existing debug object. A default will be used and the stored object will be cleared.',
      );

      localStorage.removeItem(DEBUG_STORAGE_KEY);
    }

    return {
      windowLocation: jsonObject.windowLocation,
      windowsVisible: jsonObject.windowsVisible,
      userData: jsonObject.userData,
    };
  }
}
