/**
 * Options for MathJax configuration
 */
export interface MathJaxOptions {
  options: {
    enableMenu: boolean;
    menuOptions: {
      settings: {
        zoom: 'Click';
      };
    };
  };
  startup: {
    typeset: boolean;
  };
}
/**
 * Helper class that makes working with MathJax simple
 */
export class MathJaxHelper {
  static readonly MATHJAX_DEFAULT_OPTIONS: MathJaxOptions = {
    options: {
      enableMenu: false,
      menuOptions: {
        settings: {
          zoom: 'Click',
        },
      },
    },
    startup: {
      typeset: false,
    },
  };

  /**
   * Imports MathJax library to the document.
   * @param version Version of MathJax.
   * @param options Options for MathJax. Optional.
   */
  static import(version: string, options?: MathJaxOptions): void {
    if (MathJaxHelper.scriptExistsInHead()) {
      throw new Error(
        'Cannot import MathJax library because it has already been set.',
      );
    }

    const helper = new MathJaxHelper();

    helper.configureMathJax(options ?? MathJaxHelper.MATHJAX_DEFAULT_OPTIONS);
    helper.putScriptInHead(version);
  }

  /**
   * Checks whether MathJax library has already been imported to the document.
   */
  static isImported(): boolean {
    return MathJaxHelper.scriptExistsInHead();
  }

  /**
   * Checks whether MathJax script has been defined in head
   * @private
   * @static
   */
  private static scriptExistsInHead(): boolean {
    const scriptTags = document.head.querySelectorAll('script');
    let hasScript: boolean = false;

    scriptTags.forEach(tag => {
      const src = tag.getAttribute('src');
      if (src?.includes('mathjax')) {
        hasScript = true;
      }
    });

    return hasScript;
  }

  private configureMathJax(options: MathJaxOptions): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.MathJax = options;
  }

  /**
   * Places MathJax script in head of the document
   * @param version
   * @private
   */
  private putScriptInHead(version: string): void {
    const script = document.createElement('script');
    script.src = `https://cdn.jsdelivr.net/npm/mathjax@${version}/es5/tex-svg.js`;
    script.async = true;
    document.head.appendChild(script);
  }
}
