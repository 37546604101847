import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PendingAssessmentsClient } from '../../clients/pending-assessments.client';
import { PendingAssessment } from '../../models/assessment/pending-assessment';
import { FaIconComponent } from '@compass/ui';
import { NavigationGroupDirective } from '../../directives/navigation-group.directive';
import { NavigationItemDirective } from '../../directives/navigation-item.directive';

@Component({
  selector: 'cp-par-pending-assessments',
  standalone: true,
  imports: [
    CommonModule,
    FaIconComponent,
    NavigationGroupDirective,
    NavigationItemDirective,
  ],
  templateUrl: './pending-assessments.component.html',
})
export class PendingAssessmentsComponent implements OnInit {
  protected pendingAssessments?: PendingAssessment[];

  constructor(private readonly _client: PendingAssessmentsClient) {}

  async ngOnInit(): Promise<void> {
    this.pendingAssessments = await this._client.getPendingAssessments();
  }

  protected startAssessment(startCode: string): void {
    window.location.href = `/${startCode}`;
  }
}
