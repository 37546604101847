import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../security/authentication.service';
import { StartCode } from '../../helpers/start-code';

/**
 * Service for redirecting to different URLs.
 */
@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  constructor(
    private readonly _router: Router,
    private readonly _authService: AuthenticationService,
  ) {}

  /**
   * Redirects to a specified path.
   * If a startCode is provided, it appends it to the path if not already present.
   *
   * @param {string} path - The path to redirect to.
   * @param {string} [startCode] - The startCode to append to the path (optional).
   *                              If not provided, it tries to get start code from current session.
   * @return {Promise<boolean>} - A promise that resolves to true if the navigation was successful, false otherwise.
   */
  redirect(path: string, startCode?: string): Promise<boolean> {
    startCode ??= this._authService.startCode;

    if (startCode && !path.includes(startCode)) {
      path = StartCode.addToQueryString(path, startCode);
    }

    return this._router.navigateByUrl(path);
  }
}
