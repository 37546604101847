import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  EmbeddedViewRef,
  Injectable,
  Type,
} from '@angular/core';

// This service was taken from https://stackoverflow.com/questions/39857222/angular2-dynamic-component-injection-in-root/40687392#40687392
// Some modifications were made, however, full credit should go to the author.

/**
 * Service class for creating and manipulating dynamic components.
 */
@Injectable({
  providedIn: 'root',
})
export class ComponentMakerService {
  constructor(private readonly _applicationRef: ApplicationRef) {}

  /**
   * Creates a component in the DOM.
   *
   * @template T - Type of the component.
   * @param {Type<T>} componentClass - The class of the component to be created.
   * @param {Object} [inputs={}] - Options to be passed to the component instance.
   * @param {ComponentRef<unknown> | HTMLElement} [location] - Location where the component should be inserted, if provided.
   * @returns {ComponentRef<T>} - The reference to the created component.
   */
  createInDom<T>(
    componentClass: Type<T>,
    inputs?: Partial<T>,
    location?: ComponentRef<unknown> | HTMLElement,
  ): ComponentRef<T> {
    const componentRef = createComponent(componentClass, {
      environmentInjector: this._applicationRef.injector,
    });
    const componentRootNode = this.getComponentRootNode(componentRef);

    // project the options passed to the component instance
    if (inputs) {
      this.projectComponentInputs<T>(componentRef, inputs);
    }

    this._applicationRef.attachView(componentRef.hostView);

    const container = this.getViewContainerNode(location);

    container.appendChild(componentRootNode);

    return componentRef;
  }

  /**
   * Retrieves the root view container of the application.
   *
   * @returns The ComponentRef of the root view container.
   * @throws Error if the root view container is not found.
   */
  private getRootViewContainer(): ComponentRef<unknown> {
    const rootComponents =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this._applicationRef['_rootComponents'] ?? this._applicationRef['_views'];
    if (rootComponents.length) {
      return rootComponents[0];
    }

    throw new Error('View Container not found!');
  }

  /**
   * Returns the root node of the component.
   *
   * @param {ComponentRef<unknown>} componentRef - The component reference.
   *
   * @return {HTMLElement} - The HTML element representing the root node of the component.
   */
  private getComponentRootNode(
    componentRef: ComponentRef<unknown>,
  ): HTMLElement {
    return componentRef.hostView
      ? (componentRef.hostView as EmbeddedViewRef<never>).rootNodes[0]
      : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ((componentRef as never).rootNodes[0] as HTMLElement);
  }

  /**
   * Retrieves the container node of a view.
   *
   * @param container - Optional. The component reference of the container. If not provided, the root view container will be used.
   *
   * @returns The container node as an HTMLElement.
   */
  private getViewContainerNode(
    container?: ComponentRef<unknown> | HTMLElement,
  ): HTMLElement {
    if (!container) {
      container = this.getRootViewContainer();
    }

    return container instanceof HTMLElement
      ? container
      : this.getComponentRootNode(container);
  }

  /**
   * Sets the properties of a given component using the provided options.
   *
   * @param {ComponentRef<unknown>} component - The component reference.
   * @param {unknown} options - The options object containing the property values.
   */
  private projectComponentInputs<T>(
    component: ComponentRef<T>,
    options: Partial<T>,
  ): void {
    const props = Object.getOwnPropertyNames(options);
    for (const prop of props) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      component.setInput(prop, options[prop]);
    }
  }
}
