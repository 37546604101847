import { Component } from '@angular/core';
import { TimePipe } from '@compass/pipes';
import { InactivityService } from '../../services/ui/inactivity.service';

@Component({
  selector: 'cp-par-inactivity-warning',
  standalone: true,
  imports: [TimePipe],
  templateUrl: './inactivity-warning-modal.component.html',
})
export class InactivityWarningModalComponent {
  constructor(protected readonly inactivity: InactivityService) {}
}
