import {
  AfterViewInit,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { HotkeyTextComponent } from '../components/hotkey-text/hotkey-text.component';
import { KeyboardKey } from '../services/ui/keyboard.service';
import {
  HotkeyRequirements,
  HotkeyService,
} from '../services/ui/hotkey.service';

export type KeyWithRequirement = { key: KeyboardKey; req: HotkeyRequirements };

@Directive({
  selector: '[cpParHotkey]',
  standalone: true,
})
export class HotkeyDirective implements AfterViewInit, OnDestroy {
  @Input('cpParHotkey')
  hotkey?:
    | (KeyboardKey | KeyWithRequirement)
    | (KeyboardKey | KeyWithRequirement)[];
  @Input()
  triggerEvent?: string = 'click';
  @Output()
  readonly hotkeyTriggered = new EventEmitter();

  @ContentChild(HotkeyTextComponent)
  private readonly hotkeyText?: HotkeyTextComponent;

  private readonly _element: HTMLElement;
  private readonly _hotkeyIds: number[] = [];

  constructor(
    private readonly _hotkeyService: HotkeyService,
    element: ElementRef,
  ) {
    this._element = element.nativeElement as HTMLElement;
  }

  ngOnDestroy(): void {
    for (const id of this._hotkeyIds) {
      this._hotkeyService.remove(id);
    }
  }

  ngAfterViewInit(): void {
    const hotkeys = this.getKeysWithRequirements();

    if (this.hotkeyText && hotkeys.length > 0) {
      this.setupKeyHighlight(hotkeys[0].key);
    }

    for (const hotkey of hotkeys) {
      const id = this._hotkeyService.add(
        hotkey.key,
        () => {
          if (this._element && this.triggerEvent) {
            this._element.dispatchEvent(new Event(this.triggerEvent));
          } else {
            this.hotkeyTriggered?.emit();
          }
        },
        hotkey.req,
      );
      this._hotkeyIds.push(id);
    }
  }

  private setupKeyHighlight(key: KeyboardKey): void {
    const char = this.getCharForKey(key);

    if (!char || !this.hotkeyText) return;

    this.hotkeyText.highlightedFor = char;
  }

  private getHighlightCharacter(text: string): string | undefined {
    if (!text) {
      return '';
    }

    for (const character of text) {
      const key = this.getKeyForChar(character);
      if (this._hotkeyService.isAvailable(key, {})) {
        return character;
      }
    }

    return undefined;
  }
  private getKeyForChar(character: string): KeyboardKey {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return KeyboardKey['Key' + character.toUpperCase()];
  }
  private getCharForKey(keyboardKey: KeyboardKey): string {
    const keyText = KeyboardKey[keyboardKey].toLowerCase();
    return keyText.indexOf('key') >= 0 ? keyText.replace('key', '') : '';
  }
  private getKeysWithRequirements(): KeyWithRequirement[] {
    const specifiedHotkey = this.hotkey
      ? this.hotkey
      : this.tryGetKeyboardKeyFromText();
    const hotkeys: KeyWithRequirement[] = [];

    for (const hk of Array.isArray(specifiedHotkey)
      ? specifiedHotkey
      : [specifiedHotkey]) {
      if (this.isKeyWithRequirement(hk)) {
        hotkeys.push(hk);
      } else {
        hotkeys.push({
          key: hk,
          req: {},
        });
      }
    }

    return hotkeys;
  }
  private tryGetKeyboardKeyFromText(): KeyboardKey {
    const key = this.getHighlightCharacter(this.hotkeyText?.text ?? '');
    if (key == null) {
      throw new Error(
        'Cannot infer hotkey key from usage. Either specify the hotkey key or add a hotkey-text.',
      );
    }

    return this.getKeyForChar(key);
  }
  private isKeyWithRequirement(
    key: KeyboardKey | KeyWithRequirement,
  ): key is KeyWithRequirement {
    return (key as KeyWithRequirement).req !== undefined;
  }
}
