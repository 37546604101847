import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeyboardShortcutsHelpComponent } from '../keyboard-shortcuts-help/keyboard-shortcuts-help.component';
import { SidebarModule } from '@syncfusion/ej2-angular-navigations';
import { DeviceInfoService } from '@compass/environment';
import { NextButtonComponent } from '../next-button/next-button.component';
import { PreviousButtonComponent } from '../previous-button/previous-button.component';
import { CalculatorButtonComponent } from '../calculator-button/calculator-button.component';
import { HotkeyDirective } from '../../directives/hotkey.directive';
import { KeyboardKey } from '../../services/ui/keyboard.service';

@Component({
  selector: 'cp-par-desktop-footer',
  standalone: true,
  imports: [
    CommonModule,
    KeyboardShortcutsHelpComponent,
    SidebarModule,
    NextButtonComponent,
    PreviousButtonComponent,
    CalculatorButtonComponent,
    HotkeyDirective,
  ],
  templateUrl: './desktop-footer.component.html',
  styleUrls: ['./desktop-footer.component.scss'],
})
export class DesktopFooterComponent {
  protected readonly KeyboardKey = KeyboardKey;

  constructor(protected readonly deviceInfo: DeviceInfoService) {}
}
