import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ModalService } from '@compass/notifications';
import { SafeHtmlPipe } from '@compass/pipes';
import { AssetsService } from '@compass/environment';
import { NgIf } from '@angular/common';
import { TermsAndConditionsService } from '../../services/ui/terms-and-conditions.service';

interface BodyWithLabel {
  label?: string;
  body: string;
}

@Component({
  selector: 'cp-par-participant-terms',
  templateUrl: './participant-terms.component.html',
  standalone: true,
  imports: [SafeHtmlPipe, NgIf],
})
export class ParticipantTermsComponent implements OnDestroy, AfterViewInit {
  protected terms?: BodyWithLabel;
  protected privacy?: BodyWithLabel;
  protected customText?: string;

  @ViewChild('contentContainer')
  protected contentContainer!: ElementRef;

  @ViewChild('modalTemplate')
  protected modalTemplate!: TemplateRef<unknown>;

  @Input()
  consentButton: string = 'Start';

  @Input()
  noConsentButton: string = 'Do Not Start';

  constructor(
    private readonly _modal: ModalService,
    private readonly _assets: AssetsService,
    private readonly _termsAndConditions: TermsAndConditionsService,
  ) {}

  ngOnDestroy(): void {
    this._termsAndConditions.reset();
  }

  async ngAfterViewInit(): Promise<void> {
    this._termsAndConditions.reset(this);

    this.terms = await this.loadElementContentOrDefault(
      'terms',
      '/static-files/terms-of-use.html',
    );
    this.privacy = await this.loadElementContentOrDefault(
      'privacy',
      '/static-files/privacy-policy.html',
    );
    this.customText = this.getCustomText();
  }

  request(): Promise<boolean> {
    const modal = this._modal.show(this.modalTemplate, undefined, {
      confirmText: this.consentButton,
      cancelText: this.noConsentButton,
      closeOnBackdropClick: false,
      closeOnEscape: false,
      showCloseButton: false,
    });

    return modal.result;
  }

  protected showDetails(template: string): void {
    this._modal.alert(template, undefined, {
      showCloseButton: true,
      fullscreen: true,
      allowHtml: true,
    });
  }

  private getCustomText(): string | undefined {
    return this.contentContainer.nativeElement
      ?.querySelector('header')
      ?.innerHTML?.trim();
  }

  private async loadElementContentOrDefault(
    selector: string,
    defaultAssetPath: string,
  ): Promise<BodyWithLabel> {
    const element =
      this.contentContainer.nativeElement?.querySelector(selector);

    const label = element?.getAttribute('label');
    let body = element?.innerHTML;

    if (!body || body.trim().length === 0) {
      body = (await this._assets.getText(defaultAssetPath)) ?? '';
    }

    return { label, body };
  }
}
