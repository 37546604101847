import { Injectable, OnDestroy } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Subject } from 'rxjs';
import { Interval } from '@compass/helpers';
import { ContentClient } from '../../clients/content.client';
import { Logger } from '@compass/logging';

const INACTIVITY_TIMEOUT_SECONDS = 2 * 60;

/**
 * An Injectable service for handling user inactivity.
 */
@Injectable({
  providedIn: 'root',
})
export class InactivityService implements OnDestroy {
  private readonly _interval = Interval.fromSeconds(this.onTick.bind(this), 1);
  private readonly _onIdle = new Subject<void>();
  private readonly _onTimeout = new Subject<void>();

  private readonly _idleSub = this._idle.onIdleStart.subscribe(
    this.idle.bind(this),
  );

  private _enabled: boolean = true;
  private _secondsUntilTimeout: number = INACTIVITY_TIMEOUT_SECONDS;

  readonly idle$ = this._onIdle.asObservable();
  readonly timeout$ = this._onTimeout.asObservable();

  /**
   * Whether the service is enabled.
   *
   * @returns {boolean} True if enabled otherwise false.
   */
  get enabled(): boolean {
    return this._enabled;
  }

  /**
   * Enables or disables user inactivity watch.
   *
   * @param {boolean} value - The new value for the enabled state.
   */
  set enabled(value: boolean) {
    this._enabled = value;

    if (!value) {
      this.stop();

      this._logger.debug('Inactivity tracking has been disabled.');
    } else {
      this._logger.debug('Inactivity tracking has been enabled.');
    }
  }

  /**
   * Returns the number of seconds until the inactive timeout is triggered.
   *
   * @returns {number} The number of seconds until timeout is triggered.
   */
  get secondsUntilTimeout(): number {
    return this._secondsUntilTimeout;
  }

  constructor(
    private readonly _idle: Idle,
    private readonly _client: ContentClient,
    private readonly _logger: Logger,
  ) {}

  ngOnDestroy(): void {
    this._idleSub.unsubscribe();
  }

  /**
   * Starts user inactivity tracking if it is enabled and the assessment
   * defines an inactivity timeout.
   * If the inactivity timer is not enabled or the assessment does not define an
   * inactivity timeout, the method does nothing.
   *
   * @returns {void}
   */
  start(): void {
    // The inactivity is disabled or the assessment does not define
    // inactivity timeout.
    if (!this.enabled) return;
    if (this._client.data.revision.inactivityTimerSeconds <= 0) {
      this._logger.info('Inactivity tracking is disabled by the assessment.');
      return;
    }

    this.reset();

    this._idle.watch();

    this._logger.debug('Started tracking user inactivity.');
  }

  /**
   * Stops user inactivity tracking.
   *
   * @returns {void}
   */
  stop(): void {
    this._idle.stop();

    this.reset();

    this._logger.debug('Stopped tracking user inactivity.');
  }

  idle(): void {
    this._idle.stop();

    this._interval.start();

    this._onIdle.next();
  }

  timeout(): void {
    this.stop();
    this._onTimeout.next();
  }

  private reset(): void {
    if (this._idle.isRunning()) {
      this._idle.stop();
    }

    this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this._idle.setTimeout(INACTIVITY_TIMEOUT_SECONDS);

    this._interval.stop();
    this._secondsUntilTimeout = INACTIVITY_TIMEOUT_SECONDS;

    if (this._client.data.revision.inactivityTimerSeconds > 0) {
      this._idle.setIdle(this._client.data.revision.inactivityTimerSeconds);
    }
  }

  private onTick(): void {
    this._secondsUntilTimeout--;

    if (this._secondsUntilTimeout > 0) return;

    this.timeout();
  }
}
