import { Route } from '@angular/router';
import { UnauthenticatedComponent } from './@pages/unauthenticated/unauthenticated.component';
import { AuthenticatedComponent } from './@pages/authenticated/authenticated.component';
import { inject } from '@angular/core';
import { AuthenticationService } from './shared/services/security/authentication.service';
import { dataLoadedGuard } from './shared/guards/data-loaded.guard';
import { RedirectComponent } from '@compass/ui';

export const appRoutes: Route[] = [
  // Routes that need redirecting from old site
  {
    path: 'common/unsubscribe/:startKey',
    component: RedirectComponent,
    data: {
      redirectTo: 'unsubscribe/:startKey',
    },
  },
  // Unauthenticated paths
  {
    path: '',
    component: UnauthenticatedComponent,
    canMatch: [() => !inject(AuthenticationService).isAuthenticated],
    loadChildren: () =>
      import('./@pages/unauthenticated/unauthenticated.module').then(
        m => m.UnauthenticatedModule,
      ),
  },
  // Authenticated paths
  {
    path: ':startKey',
    component: AuthenticatedComponent,
    canMatch: [() => inject(AuthenticationService).isAuthenticated],
    loadChildren: () =>
      import('./@pages/authenticated/authenticated.module').then(
        m => m.AuthenticatedModule,
      ),
    canActivate: [dataLoadedGuard],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
