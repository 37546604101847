import { Injectable, Optional } from '@angular/core';
import { Logger } from '@compass/logging';

/**
 * Service for handling assets application assets.
 */
@Injectable({ providedIn: 'root' })
export class AssetsService {
  constructor(@Optional() private readonly _logger?: Logger) {}

  /**
   * Retrieves the text content of a file located at the specified path.
   *
   * @param {string} path - The relative path to the file.
   * @returns {Promise<string | undefined>} - A promise that resolves to the text content of the file, or undefined if an error occurred.
   */
  async getText(path: string): Promise<string | undefined> {
    path = path.startsWith('/') ? path.substring(1) : path;

    const response = await fetch('./assets/' + path);

    if (!response.ok) {
      this._logger?.error(
        `Failed to fetch asset: ${path}. Error: ${response.statusText}`,
      );
      return undefined;
    }

    return await response.text();
  }
}
