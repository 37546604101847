/**
 * A class that provides interval functionality for executing a callback
 * function multiple times after a specified period of time.
 * This is a wrapper around the native JS interval for ease of starting and cancellation
 */
export class Interval {
  private _interval?: unknown;

  /**
   * Determines if the interval is currently running.
   *
   * @returns {boolean} true if the interval is running, false otherwise.
   */
  get isRunning(): boolean {
    return this._interval !== undefined;
  }

  private constructor(
    private readonly _callback: () => void,
    private readonly _milliseconds: number,
    start: boolean,
  ) {
    if (start) {
      this.start();
    }
  }

  /**
   * Starts the interval
   *
   * @return {void}
   */
  start(): void {
    if (this._interval) return;

    this._interval = setInterval(this._callback, this._milliseconds);
  }

  /**
   * Stops the interval
   *
   * @return {void}
   */
  stop(): void {
    if (!this._interval) return;

    clearInterval(this._interval as never);
    this._interval = undefined;
  }

  /**
   * Creates an interval instance with a given callback to be executed after a specified number of milliseconds.
   *
   * @param {function} callback - The function to be executed after the specified delay.
   * @param {number} milliseconds - The number of milliseconds to delay before executing the callback.
   * @param {boolean} start - Optional. Specifies whether to start the interval immediately. Default is false.
   * @return {Interval} - The Interval object representing the created interval.
   */
  public static fromMilliseconds(
    callback: () => void,
    milliseconds: number,
    start: boolean = false,
  ): Interval {
    return new Interval(callback, milliseconds, start);
  }

  /**
   * Creates an Interval instance with a given callback to be executed after a specified number of seconds.
   *
   * @param {function} callback - The callback function to be executed.
   * @param {number} seconds - The number of seconds before executing the callback.
   * @param {boolean} [start=false] - Whether to start the interval immediately. Default is false.
   *
   * @return {Interval} - The created Interval instance.
   */
  public static fromSeconds(
    callback: () => void,
    seconds: number,
    start: boolean = false,
  ): Interval {
    return Interval.fromMilliseconds(callback, seconds * 1000, start);
  }
}
