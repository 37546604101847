<!-- @sonar-disable -->

<table class="table table-sm table-striped" role="presentation">
  <tbody>
    <!-- Tracking data -->
    <tr>
      <td colspan="2">
        <span class="fs-5 my-2 fw-bold">Item tracking data</span>
      </td>
    </tr>
    <tr>
      <td>Shown to participant count</td>
      <td>
        {{ currentItemProgress.shownToParticipantCount }}
      </td>
    </tr>

    <tr>
      <td>Seconds visible</td>
      <td>
        {{ currentItemProgress.secondsVisibleCount }}
      </td>
    </tr>

    <!-- Options data -->
    <tr>
      <td colspan="2">
        <span class="fs-5 my-2 fw-bold">Item options</span>
      </td>
    </tr>
    <ng-container
      *ngFor="let option of presentationItem.options; let i = index">
      <tr>
        <td colspan="2">
          <span class="my-2 fw-bold">
            Option {{ i + 1 }}
            <ng-container *ngIf="isOptionSelected(option.questionOptionId)">
              - SELECTED
            </ng-container>
          </span>
        </td>
      </tr>
      <tr *ngFor="let item of option | keyvalue">
        <td>{{ item.key }}</td>
        <td>{{ item.value }}</td>
      </tr>
    </ng-container>
    <tr *ngIf="presentationItem.options.length === 0">
      <td colspan="2">
        <span class="my-2">Presentation item has no options</span>
      </td>
    </tr>

    <!-- Object dump data -->
    <tr>
      <td colspan="2">
        <span class="fs-5 my-2 fw-bold">Item value dump</span>
      </td>
    </tr>
    <tr *ngFor="let itemKey of presentationItem | keys">
      <td>{{ itemKey }}</td>
      <td>{{ getItemValue(itemKey) }}</td>
    </tr>
  </tbody>
</table>
