<!-- eslint-disable-next-line -->
<div
  [style.width]="enablePanZoom() ? thumbWidth + 20 + 'px' : '100%'"
  [style.height]="enablePanZoom() ? thumbHeight + 20 + 'px' : '100%'"
  (mouseover)="showPanZoom()"
  (mouseout)="showThumbnail()"
  (touchmove)="onTouchMove($event)"
  (touchstart)="onTouchStart($event)"
  (touchend)="onTouchEnd($event)"
  class="pan-zoom-wrapper"
  [class.enabled]="enablePanZoom()"
  #panZoom>
  <div
    class="pan-zoom"
    [style.background]="'url(' + displayImage + ')'"
    [style.width]="thumbWidth + 'px'"
    [style.height]="thumbHeight + 'px'"
    (window:resize)="onResize()"></div>
</div>
<div
  class="zoom-instructions font-italic text-black-50 text-right mt-1"
  *ngIf="enablePanZoom()">
  {{ zoomInstructions }}
</div>
