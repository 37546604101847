import { CanActivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { AuthenticationService } from '../services/security/authentication.service';
import { Logger } from '@compass/logging';
import { ContentClient } from '../clients/content.client';
import { RedirectService } from '../services/ui/redirect.service';

let initialNavigation = true;

/**
 * Guard that prevents navigation to a page without
 * assessment data being loaded.
 */
export const dataLoadedGuard: CanActivateFn = async (): Promise<boolean> => {
  // Only run this guard on first navigation
  if (!initialNavigation) {
    initialNavigation = false;
    return true;
  }

  const redirectService = inject(RedirectService);
  const client = inject(ContentClient);
  const authService = inject(AuthenticationService);
  const logger = inject(Logger);

  const startCode = authService.startCode;

  // Load the content and navigate away if it's not loaded
  await client.load();

  if (!client.hasContent) {
    logger.error(
      'Navigation to assessment page was cancelled, because the data was not fetched.',
    );

    authService.logOut();
    await redirectService.redirect('/error', startCode);
    return false;
  }

  return true;
};
