import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressStoreService } from '../../../services/security/progress-store.service';
import { SessionService } from '../../../services/security/session.service';
import { DebugService } from '@compass/environment';

const SESSION_TRACKING_DEBUG_STORAGE_KEY = 'session-tracking-enabled';

@Component({
  selector: 'cp-par-debug-actions',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './debug-actions.component.html',
})
export class DebugActionsComponent {
  constructor(
    private readonly _debug: DebugService,
    protected readonly progressStore: ProgressStoreService,
    protected readonly session: SessionService,
  ) {
    const sessionTracking = this._debug.getData<boolean>(
      SESSION_TRACKING_DEBUG_STORAGE_KEY,
    );

    if (sessionTracking === undefined) return;

    this.session.enabled = sessionTracking;
  }

  protected toggleProgressPersistence(): void {
    if (this.progressStore.isTrackingEnabled) {
      this.progressStore.stopProgressTracking();
    } else {
      this.progressStore.startProgressTracking();
    }
  }

  protected toggleSessionTracking(): void {
    this.session.enabled = !this.session.enabled;

    this._debug.storeData(
      SESSION_TRACKING_DEBUG_STORAGE_KEY,
      this.session.enabled,
    );
  }
}
