import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebugActionsComponent } from '../debug-actions/debug-actions.component';
import {
  AccordionModule,
  ExpandedEventArgs,
} from '@syncfusion/ej2-angular-navigations';
import { DebugPresentationItemInfoComponent } from '../debug-presentation-item-info/debug-presentation-item-info.component';
import { PresentationService } from '../../../services/assessment/presentation.service';
import { DebugContentGroupInfoComponent } from '../debug-content-group-info/debug-content-group-info.component';
import { DebugService } from '@compass/environment';
import { DebugNavigationComponent } from '../debug-navigation/debug-navigation.component';
import { DebugTimerComponent } from '../debug-timer/debug-timer.component';
import { DebugProgressComponent } from '../debug-progress/debug-progress.component';
import { ProgressService } from '../../../services/assessment/progress.service';
import { DebugInactivityComponent } from '../debug-inactivity/debug-inactivity.component';

const DEBUG_STORAGE_KEY = 'expanded-debug-items';

@Component({
  selector: 'cp-par-debug-window',
  standalone: true,
  imports: [
    CommonModule,
    DebugActionsComponent,
    AccordionModule,
    DebugPresentationItemInfoComponent,
    DebugContentGroupInfoComponent,
    DebugNavigationComponent,
    DebugTimerComponent,
    DebugProgressComponent,
    DebugInactivityComponent,
  ],
  templateUrl: './debug-window.component.html',
  styleUrls: ['./debug-window.component.scss'],
})
export class DebugWindowComponent implements OnInit {
  protected expandedAccordionItems: number[] = [];

  constructor(
    protected readonly progress: ProgressService,
    protected readonly presentation: PresentationService,
    private readonly _debug: DebugService,
  ) {}

  ngOnInit(): void {
    this.expandedAccordionItems =
      this._debug.getData<number[]>(DEBUG_STORAGE_KEY) ?? [];
  }

  expanded(data: ExpandedEventArgs): void {
    if (data.index === undefined) return;

    const arrIndex = this.expandedAccordionItems.indexOf(data.index);

    if (data.isExpanded && arrIndex === -1) {
      this.expandedAccordionItems.push(data.index);
    } else if (!data.isExpanded && arrIndex >= 0) {
      this.expandedAccordionItems.splice(arrIndex, 1);
    }

    this._debug.storeData(DEBUG_STORAGE_KEY, this.expandedAccordionItems);
  }
}
