<div
  class="card bg-light p-2"
  *ngIf="pendingAssessments && pendingAssessments.length > 0">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col fs-2">
        You Have
        {{
          pendingAssessments.length === 1
            ? 'Another Assessment'
            : 'Other Assessments'
        }}
        to Complete
      </div>
    </div>
    <div
      class="row mt-3 align-items-center"
      cpParNavigationGroup
      *ngFor="let pendingAssessment of pendingAssessments">
      <div class="col-auto">
        <button
          #navItem="navigationItem"
          class="btn btn-secondary btn-sm"
          [ngClass]="{
            'border-2 border-info': navItem.highlighted,
          }"
          cpParNavigationItem
          (navigationItemSelected)="
            startAssessment(pendingAssessment.startCode)
          "
          (click)="startAssessment(pendingAssessment.startCode)">
          Start Now
        </button>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-12 col-md-auto">
            {{ pendingAssessment.assessmentName }}
          </div>
          <div class="col-12 col-md p-md-0">
            (assigned {{ pendingAssessment.createdOn | date }})
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
