import { Injectable } from '@angular/core';
import { Logger } from '@compass/logging';
import { Interval } from '@compass/helpers';
import {
  AssessmentService,
  AssessmentExitReason,
} from '../assessment/assessment.service';

interface SessionData {
  sessionId: string;
}

/**
 * Service for managing user session for the current assessment
 */
@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private readonly _checkInterval = Interval.fromSeconds(
    this.onSessionCheck.bind(this),
    1,
  );

  private _enabled: boolean = true;
  private _startCode?: string;
  private _sessionId?: string;

  /**
   * Returns a boolean indicating whether session tracking is enabled or not.
   *
   * @returns {boolean} - True if session tracking is enabled, false otherwise.
   */
  get enabled(): boolean {
    return this._enabled;
  }

  /**
   * Set the enabled state of session tracking.
   *
   * @param {boolean} value - The new value to set for the enabled state.
   */
  set enabled(value: boolean) {
    this.stop();

    this._enabled = value;
  }

  constructor(
    private readonly _logger: Logger,
    private readonly _assessment: AssessmentService,
  ) {}

  /**
   * Starts the user session
   *
   * @returns {void}
   */
  start(startCode: string, sessionId: string): void {
    if (!this.enabled) {
      this._logger.debug('Session tracking is disabled and will not start.');
      return;
    }

    if (!startCode || !sessionId) {
      throw new Error('Start code and session ID is required.');
    }

    if (this._checkInterval.isRunning) return;

    this._startCode = startCode;
    this._sessionId = sessionId;

    this.storeSessionData({ sessionId });

    this._checkInterval.start();

    this._logger.debug(`Started session ID [${this._sessionId}].`);
  }

  /**
   * Stops the current session.
   *
   * @returns {void}
   */
  stop(): void {
    if (!this._checkInterval.isRunning) return;

    this._checkInterval.stop();

    this._logger.debug(`Stopped session ID [${this._sessionId}].`);

    this._sessionId = undefined;
  }

  private getSessionData(): Partial<SessionData> {
    if (!this._startCode) return {};

    const data = localStorage.getItem(this._startCode);

    if (!data) return {};

    try {
      return JSON.parse(data) as SessionData;
    } catch (error) {
      this._logger.debug('Failed to parse session data.');
      return {};
    }
  }

  private storeSessionData(data: Partial<SessionData>): void {
    if (!this._startCode) return;

    const existingData = this.getSessionData();

    const newData = { ...existingData, ...data };

    localStorage.setItem(this._startCode, JSON.stringify(newData));
  }

  private onSessionCheck(): void {
    const storedSessionId = this.getSessionData().sessionId;

    // If the session IDs match then we pass the check and we do nothing
    if (storedSessionId === this._sessionId) return;

    this._logger.warn(
      `Session ID [${this._sessionId}] has been invalidated. The user will be logged out.`,
    );

    // Current session ID is not the most current session - expire it
    this._assessment.exit(AssessmentExitReason.SessionExpired);
  }
}
