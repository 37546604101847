import { Component } from '@angular/core';
import { HotkeyDirective } from '../../directives/hotkey.directive';
import { KeyboardKey } from '../../services/ui/keyboard.service';
import { HotkeyTextComponent } from '../hotkey-text/hotkey-text.component';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { ProgressService } from '../../services/assessment/progress.service';
import { PresentationService } from '../../services/assessment/presentation.service';
import { TimerComponent } from '../timer/timer.component';
import { CommonModule } from '@angular/common';
import {
  AssessmentState,
  AssessmentStateService,
} from '../../services/assessment/assessment-state.service';
import { TimerService } from '../../services/assessment/timer.service';

@Component({
  selector: 'cp-par-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HotkeyDirective,
    HotkeyTextComponent,
    ProgressBarComponent,
    TimerComponent,
  ],
})
export class HeaderComponent {
  protected readonly KeyboardKey = KeyboardKey;
  protected readonly AssessmentState = AssessmentState;

  constructor(
    protected readonly progress: ProgressService,
    protected readonly presentation: PresentationService,
    protected readonly state: AssessmentStateService,
    protected readonly timer: TimerService,
  ) {}

  protected canShowTimer(): boolean {
    return (
      this.state.currentState === AssessmentState.InProgress &&
      this.presentation.current.contentGroup.isTimed
    );
  }

  protected canShowProgress(): boolean {
    return (
      this.state.currentState === AssessmentState.InProgress &&
      !!this.presentation.current.contentGroup.progressBarSourceId
    );
  }
}
