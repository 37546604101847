<div class="d-none" #contentContainer>
  <ng-content />
</div>

<ng-template #modalTemplate>
  <div *ngIf="customText" [innerHTML]="customText | safeHtml"></div>
  <p *ngIf="!customText">
    By clicking <strong>Start</strong>, you agree to the following:
  </p>
  <div>
    <button
      *ngIf="terms"
      (click)="showDetails(terms.body)"
      class="btn btn-sm btn-dark text-light rounded-pill text-decoration-underline me-2">
      {{ terms.label ?? 'Terms of Use' }}
    </button>
    <button
      *ngIf="privacy"
      (click)="showDetails(privacy.body)"
      class="btn btn-sm btn-dark text-light rounded-pill text-decoration-underline">
      {{ privacy.label ?? 'Privacy Policy' }}
    </button>
  </div>
</ng-template>
