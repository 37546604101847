<form
  class="mt-2 mb-2 needs-validation d-flex flex-column gap-2"
  [formGroup]="identityForm.instance"
  novalidate>
  <div class="row gx-2" *ngIf="identityForm.nameFirst && identityForm.nameLast">
    <div class="col form-floating">
      <input
        (blur)="formatName('nameFirst')"
        id="firstNameInput"
        type="text"
        class="form-control"
        [placeholder]="nameFirstLabel"
        formControlName="nameFirst"
        [ngClass]="{
          'is-valid': showValidMessage(identityForm.nameFirst),
          'is-invalid': showInvalidMessage(identityForm.nameFirst),
        }" />
      <label for="firstNameInput"
        >{{ nameFirstLabel }}{{ requiredValues.name ? '*' : '' }}</label
      >
      <div class="invalid-feedback">
        <ng-container> {{ nameFirstLabel }} is required </ng-container>
      </div>
    </div>
    <div class="col form-floating">
      <input
        (blur)="formatName('nameLast')"
        id="lastNameInput"
        type="text"
        class="form-control"
        [placeholder]="nameLastLabel"
        formControlName="nameLast"
        [ngClass]="{
          'is-valid': showValidMessage(identityForm.nameLast),
          'is-invalid': showInvalidMessage(identityForm.nameLast),
        }" />
      <label for="lastNameInput">
        {{ nameLastLabel }}{{ requiredValues.name ? '*' : '' }}
      </label>
      <div class="invalid-feedback">{{ nameLastLabel }} is required</div>
    </div>
  </div>
  <div class="form-floating" *ngIf="identityForm.email">
    <input
      id="emailInput"
      type="text"
      class="form-control"
      [placeholder]="emailLabel"
      formControlName="email"
      [ngClass]="{
        'is-valid': showValidMessage(identityForm.email),
        'is-invalid': showInvalidMessage(identityForm.email),
      }" />
    <label for="emailInput">
      {{ emailLabel }}{{ requiredValues.email ? '*' : '' }}
    </label>
    <div class="invalid-feedback">Please enter a valid email address</div>
  </div>
  <div class="form-floating" *ngIf="identityForm.phone">
    <input
      id="phoneInput"
      type="text"
      class="form-control"
      [placeholder]="phoneLabel"
      formControlName="phone"
      [ngClass]="{
        'is-valid': showValidMessage(identityForm.phone),
        'is-invalid': showInvalidMessage(identityForm.phone),
      }" />
    <label for="phoneInput">
      {{ phoneLabel }}{{ requiredValues.phone ? '*' : '' }}
    </label>
    <div class="invalid-feedback">Please enter a valid phone number</div>
  </div>
</form>
