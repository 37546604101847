import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogStyle } from '../../types/dialog-style';
import { FaIconComponent } from '@compass/ui';

@Component({
  selector: 'cp-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
  standalone: true,
  imports: [CommonModule, FaIconComponent],
})
export class ModalHeaderComponent implements OnChanges {
  @Input({ required: true })
  title!: string | TemplateRef<unknown>;

  @Input()
  titleContext?: Record<string, unknown>;

  @HostBinding('class')
  @Input()
  dialogStyle: DialogStyle = 'normal';

  @Input()
  showCloseButton: boolean = true;

  @Output()
  readonly closeClicked = new EventEmitter<void>();

  protected isSimpleTitle: boolean = true;

  ngOnChanges(): void {
    this.isSimpleTitle = typeof this.title === 'string';
  }
}
