<ejs-accordion
  class="mt-2"
  (expanded)="expanded($event)"
  [expandedIndices]="expandedAccordionItems">
  <e-accordionitems>
    <e-accordionitem header="Assessment actions">
      <ng-template #content>
        <cp-par-debug-actions />
      </ng-template>
    </e-accordionitem>

    <e-accordionitem header="Hardcore navigation">
      <ng-template #content>
        <cp-par-debug-navigation />
      </ng-template>
    </e-accordionitem>

    <e-accordionitem header="User inactivity">
      <ng-template #content>
        <cp-par-debug-inactivity />
      </ng-template>
    </e-accordionitem>

    <e-accordionitem header="Current presentation item">
      <ng-template #content>
        <cp-par-debug-presentation-item-info
          [presentationItem]="presentation.current.presentationItem" />
      </ng-template>
    </e-accordionitem>

    <e-accordionitem header="Current content group">
      <ng-template #content>
        <cp-par-debug-content-group-info
          [contentGroup]="presentation.current.contentGroup" />
      </ng-template>
    </e-accordionitem>

    <e-accordionitem header="Current content progress">
      <ng-template #content>
        <cp-par-debug-progress
          [groupProgress]="progress.progressForCurrentGroup" />
      </ng-template>
    </e-accordionitem>

    <e-accordionitem header="Current timer">
      <ng-template #content>
        <cp-par-debug-timer />
      </ng-template>
    </e-accordionitem>
  </e-accordionitems>
</ejs-accordion>
