import { Injectable } from '@angular/core';
import { JwtProvider, Requester } from '@compass/http';
import { Logger } from '@compass/logging';
import { ApplicationEnvironment } from '@compass/environment';
import { AuthenticationResponse } from '../../responses/authentication.response';

/**
 * Result of an authentication action
 */
export enum AuthenticationResult {
  Success,
  NotFound,
  Disabled,
  Error,
}

/**
 * Service that tracks authentication status of participant
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements JwtProvider {
  private _sessionId?: string;
  private _token?: string;
  private _startCode?: string;

  /**
   * Start code that the current user is authenticated with
   */
  get startCode(): string {
    return this._startCode ?? '';
  }

  get sessionId(): string {
    return this._sessionId ?? '';
  }

  /**
   * Whether user is authenticated
   * @returns True if user is authenticated otherwise false
   */
  get isAuthenticated(): boolean {
    return this.getToken() !== undefined;
  }

  constructor(
    private readonly _requester: Requester,
    private readonly _logger: Logger,
    private readonly _appEnv: ApplicationEnvironment,
  ) {}

  /**
   * Logs out the user and clears session data.
   *
   * @returns {void}
   */
  logOut(): void {
    if (!this.isAuthenticated) return;

    this._logger.debug(
      `Assessment with start key [${this.startCode}] was logged out.`,
    );

    this._startCode = undefined;
    this._token = undefined;
    this._sessionId = undefined;
  }

  /**
   * Tries to authenticate user and get token
   * @param startCode Start key for the assessment
   * @returns {@link AuthenticationResult}
   */
  async authenticate(startCode: string): Promise<AuthenticationResult> {
    const response = await this._requester
      .get<AuthenticationResponse>('start')
      .addRouteSegments(startCode)
      .send();

    if (!response.isSuccess) {
      return this.getAuthenticationResultFromStatus(response.status);
    }

    if (response.data) {
      this.authenticationSucceeded(startCode, response.data);
      return AuthenticationResult.Success;
    } else {
      this.authenticationFailed(
        startCode,
        'HTTP request succeeded but no token was returned from the server.',
      );
      return AuthenticationResult.Error;
    }
  }

  private getAuthenticationResultFromStatus(
    status: number,
  ): AuthenticationResult {
    switch (status) {
      case 404:
        return AuthenticationResult.NotFound;
      case 403:
        return AuthenticationResult.Disabled;
      default:
        return AuthenticationResult.Error;
    }
  }

  private authenticationSucceeded(
    startCode: string,
    response: AuthenticationResponse,
  ): void {
    this._token = response.token;
    this._sessionId = response.sessionId;
    this._startCode = startCode;

    // Log the results
    this._logger.debug(
      `Successfully authenticated with start key [${startCode}].`,
    );
  }

  private authenticationFailed(startCode: string, error?: unknown): void {
    this._startCode = undefined;
    this._token = undefined;

    // Log the results
    if (error && this._appEnv.isDevelopment) {
      this._logger.error(error);
    }

    this._logger.debug(`Failed to authenticate with start key [${startCode}].`);
  }

  /**
   * Gets authentication token
   * @returns Token if authenticated otherwise undefined
   */
  getToken(): string | undefined {
    return this._token;
  }
}
