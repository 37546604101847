<button
  class="btn btn-primary d-flex justify-content-between align-items-center py-3 px-4 gap-2"
  *ngIf="calculator.enabled"
  [ngbTooltip]="getCalculatorToolTip()"
  (click)="calculator.toggle()"
  cpDoNotFocus>
  <cp-fa-icon iconStyle="light">calculator</cp-fa-icon>
  <ng-container *ngIf="deviceInfo.device.isDesktop">
    <p class="my-auto">
      <ng-container *ngIf="!calculatorResult"> <u>C</u>alculator </ng-container>
      <ng-container *ngIf="calculatorResult">
        {{ calculatorResult }}
      </ng-container>
    </p>
  </ng-container>

  <span class="ms-1" *ngIf="calculatorResult && !deviceInfo.device.isDesktop">
    {{ calculatorResult }}
  </span>
</button>
