import { Component, Input, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cp-template-modal',
  templateUrl: './template-modal.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class TemplateModalComponent {
  @Input({ required: true })
  template!: TemplateRef<unknown>;

  @Input()
  templateContext?: Record<string, unknown>;
}
