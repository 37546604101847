import { Pipe, PipeTransform } from '@angular/core';

/**
 * Transforms seconds into a formatted time. E.g.:
 * 5400s => 1:30:00
 * 1799 => 29:59
 * 6 => 0:06
 */
@Pipe({
  name: 'time',
  standalone: true,
})
export class TimePipe implements PipeTransform {
  transform(seconds: number): string {
    let text = '';

    const hours = Math.floor(seconds / 3600);
    seconds = seconds - hours * 3600;
    const minutes = Math.floor(seconds / 60);
    seconds = seconds - minutes * 60;

    if (hours > 0) {
      text += `${hours}:`;
    }

    if (minutes >= 0) {
      text += `${minutes}:`;
    }

    if (seconds >= 0) {
      text += `${seconds < 10 ? '0' : ''}${seconds}`;
    }

    return text.trim();
  }
}
