import { AbstractControl, ValidationErrors } from '@angular/forms';
import { emailValidator } from './email';
import { phoneValidator } from './phone';

export class ValidatorFactory {
  /**
   * Creates validator that checks for valid email
   * @param control Control to validate
   * @see emailValidator
   * @returns ValidatorFn
   */
  static email(control: AbstractControl): ValidationErrors | null {
    return emailValidator(control);
  }

  /**
   * Creates a validator that checks for
   * a valid phone number
   * @see phoneValidator
   * @param control control to validate
   */
  static phone(control: AbstractControl): ValidationErrors | null {
    return phoneValidator(control);
  }
}
