import { Component } from '@angular/core';
import { DeviceInfoService } from '@compass/environment';
import { CommonModule } from '@angular/common';
import { CalculatorService } from '../../services/ui/calculator.service';

interface KeyShortcut {
  title: string;
  keys: string[];
  hidden?: () => boolean;
}

@Component({
  selector: 'cp-par-keyboard-shortcuts-help',
  templateUrl: 'keyboard-shortcuts-help.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class KeyboardShortcutsHelpComponent {
  protected shortcuts: KeyShortcut[] = [
    { title: 'Next page', keys: ['Right Arrow'] },
    { title: 'Previous page', keys: ['Left Arrow'] },
    { title: 'Next option', keys: ['Down Arrow'] },
    { title: 'Previous option', keys: ['Up Arrow'] },
    {
      title: 'Select option',
      keys: [this._device.device.isMac ? 'Return' : 'Space'],
    },
    { title: 'Help', keys: ['Shift', 'H'] },
    {
      title: 'Shortcuts help',
      keys: [this.optionOrAltText(), '/'],
    },
    {
      title: 'Calculator',
      keys: [this.optionOrAltText(), 'C'],
      hidden: () => !this._calculator.enabled,
    },
  ];

  constructor(
    private readonly _device: DeviceInfoService,
    private readonly _calculator: CalculatorService,
  ) {}

  private optionOrAltText(): string {
    return this._device.device.isMac ? 'Option' : 'Alt';
  }
}
