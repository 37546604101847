import { Injectable } from '@angular/core';
import { Requester } from '@compass/http';
import { Logger } from '@compass/logging';
import { PendingAssessment } from '../models/assessment/pending-assessment';

/**
 * Service class for handling pending assessments.
 *
 * @class
 */
@Injectable({
  providedIn: 'root',
})
export class PendingAssessmentsClient {
  private _pendingAssessments?: PendingAssessment[];

  constructor(
    private readonly _requester: Requester,
    private readonly _logger: Logger,
  ) {}

  /**
   * Retrieves the pending assessments from the server.
   *
   * @returns {Promise<PendingAssessment[]>} A promise that resolves to an array of pending assessments.
   */
  async getPendingAssessments(): Promise<PendingAssessment[]> {
    if (this._pendingAssessments) {
      return this._pendingAssessments;
    }

    const response = await this._requester
      .get<PendingAssessment[]>('assessmentRevision/open')
      .send();

    if (!response.isSuccess) {
      this._logger.error(
        'Failed to load pending assessments.',
        response.errors,
      );

      return [];
    }

    this._pendingAssessments = response.data ?? [];

    return this._pendingAssessments;
  }
}
