import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ReactiveFormsModule } from '@angular/forms';
import { formatPersonName } from '@compass/helpers';
import { CommonModule } from '@angular/common';
import {
  IdentityFormConfig,
  IdentityFormInstance,
  IdentityService,
} from '../../services/ui/identity.service';
import { fromEvent, skip } from 'rxjs';

type IdentityType = 'required' | 'optional' | 'hidden';

@Component({
  selector: 'cp-par-identity',
  templateUrl: 'identity.component.html',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
})
export class IdentityComponent implements OnInit, OnDestroy {
  // This is to listen for button click even when it is disabled
  private readonly _documentClickSub = fromEvent(document, 'click')
    .pipe(skip(1))
    .subscribe(evt => {
      if (!this.isEventTargetButton(evt.target)) return;

      this.updateFormValidity();
    });

  protected identityForm!: IdentityFormInstance;

  @Input()
  name: IdentityType = 'required';

  @Input()
  nameFirstLabel: string = 'First Name';

  @Input()
  nameLastLabel: string = 'Last Name';

  @Input()
  email: IdentityType = 'required';

  @Input()
  emailLabel: string = 'Email';

  @Input()
  phone: IdentityType = 'required';

  @Input()
  phoneLabel: string = 'Phone';

  get requiredValues(): { name: boolean; email: boolean; phone: boolean } {
    return {
      name: this.isRequired(this.name),
      email: this.isRequired(this.email),
      phone: this.isRequired(this.phone),
    };
  }

  constructor(protected readonly identity: IdentityService) {}

  ngOnInit(): void {
    // Gather control information from inputs
    const identityFormConfig: Partial<IdentityFormConfig> = {};

    if (this.name !== 'hidden') {
      identityFormConfig['nameFirst'] = {
        required: this.name === 'required',
      };
      identityFormConfig['nameLast'] = {
        required: this.name === 'required',
      };
    }

    if (this.email !== 'hidden') {
      identityFormConfig['email'] = {
        required: this.email === 'required',
      };
    }

    if (this.phone !== 'hidden') {
      identityFormConfig['phone'] = {
        required: this.phone === 'required',
      };
    }

    this.identityForm = this.identity.createForm(identityFormConfig);
  }

  ngOnDestroy(): void {
    this.identityForm.destroy();
    this._documentClickSub.unsubscribe();
  }

  protected showValidMessage(control: AbstractControl): boolean {
    return control.valid && control.dirty && control.value;
  }

  protected showInvalidMessage(control?: AbstractControl): boolean {
    return control && (control.touched || control.value) && control.invalid;
  }

  /**
   * Formats first and last names
   * @param control name of control to be formatted
   */
  protected formatName(control: string): void {
    if (!this.identityForm.instance.controls[control]) return;

    this.identityForm.instance.controls[control].setValue(
      formatPersonName(this.identityForm.instance.controls[control].value),
    );
  }

  private isRequired(identityType: IdentityType): boolean {
    return identityType !== 'optional' && identityType !== 'hidden';
  }

  private updateFormValidity(): void {
    const invalidControls = Object.values(
      this.identityForm.instance.controls,
    ).filter(c => c.invalid);

    for (const control of invalidControls) {
      control.markAsTouched();
    }
  }

  private isEventTargetButton(target: EventTarget | null): boolean {
    if (target instanceof HTMLButtonElement) return true;

    return (
      target instanceof Node && target.firstChild instanceof HTMLButtonElement
    );
  }
}
