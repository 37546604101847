<ejs-dialog
  [isModal]="true"
  (close)="cancel()"
  [footerTemplate]="
    options.showConfirm || options.showCancel ? footer : undefined
  "
  [closeOnEscape]="options.closeOnEscape"
  (overlayClick)="onOverlayClicked()"
  class="w-100"
  [ngClass]="{
    'w-md-30': !options.fullscreen,
    caption: options.fullscreen,
    'h-100': options.fullscreen,
  }"
  [header]="
    displayOptions.title || options.showCloseButton ? header : undefined
  ">
  <ng-container *ngIf="displayOptions.type === 'content' && !options.allowHtml">
    {{ displayOptions.content }}
  </ng-container>
  <ng-container *ngIf="displayOptions.type === 'content' && options.allowHtml">
    <div [innerHTML]="displayOptions.content | safeHtml"></div>
  </ng-container>
  <ng-container *ngIf="displayOptions.type === 'component'">
    <ng-container
      *ngComponentOutlet="
        displayOptions.component;
        inputs: displayOptions.context ?? {}
      " />
  </ng-container>
</ejs-dialog>

<ng-template #footer>
  <button
    class="btn btn-link me-4"
    *ngIf="options.showCancel"
    (click)="cancel()">
    {{ options.cancelText }}
  </button>
  <button
    class="btn btn-primary"
    *ngIf="options.showConfirm"
    (click)="confirm()"
    [disabled]="
      confirmPending ||
      (options.confirmDisabled !== undefined && options.confirmDisabled())
    ">
    <div class="spinner-border spinner-border-sm" *ngIf="confirmPending"></div>
    <ng-container *ngIf="!confirmPending">
      {{ options.confirmText }}
    </ng-container>
  </button>
</ng-template>

<ng-template #header>
  <cp-modal-header
    (closeClicked)="cancel()"
    [showCloseButton]="options.showCloseButton"
    [title]="displayOptions.title!"
    [titleContext]="displayOptions.titleContext"
    [dialogStyle]="dialogStyle" />
</ng-template>
