export class String {
  /**
   * Checks whether string is null, undefined, or
   * consists of white-space characters
   * @param value Value to check
   * @returns True if string is not null and contains at least one non-whitespace character
   */
  public static isNullOrWhitespace(value?: string): boolean {
    return !value || /^\s*$/.test(value);
  }
}
