import { Injectable } from '@angular/core';
import { SubmitSpinnerComponent } from '../../components/submit-spinner/submit-spinner.component';
import { SubmitErrorComponent } from '../../components/submit-error/submit-error.component';
import { ModalService } from '@compass/notifications';
import { ProgressStoreService } from '../security/progress-store.service';
import { Logger } from '@compass/logging';
import { Requester } from '@compass/http';
import {
  AssessmentState,
  AssessmentStateService,
} from './assessment-state.service';
import { AssessmentContentService } from './assessment-content.service';
import { TimerService } from './timer.service';
import { PresentationService } from './presentation.service';
import { ProgressService } from './progress.service';
import { AuthenticationService } from '../security/authentication.service';
import { ContentClient } from '../../clients/content.client';
import { RedirectService } from '../ui/redirect.service';
import { SourceDataService } from '../ui/source-data.service';
import { QueryStringKey } from '../../constants/query-string-key';

export enum AssessmentExitReason {
  Error,
  InactivityTimeout,
  SessionExpired,
}

@Injectable({
  providedIn: 'root',
})
export class AssessmentService {
  private _initialized = false;

  constructor(
    private readonly _modal: ModalService,
    private readonly _progressStore: ProgressStoreService,
    private readonly _logger: Logger,
    private readonly _requester: Requester,
    private readonly _state: AssessmentStateService,
    private readonly _content: AssessmentContentService,
    private readonly _timer: TimerService,
    private readonly _presentation: PresentationService,
    private readonly _progress: ProgressService,
    private readonly _auth: AuthenticationService,
    private readonly _client: ContentClient,
    private readonly _redirect: RedirectService,
    private readonly _sourceData: SourceDataService,
  ) {}

  exit(reason: AssessmentExitReason): void {
    this._logger.debug(
      `Exiting assessment - all session data will be destroyed and user will be logged out. Reason: [${AssessmentExitReason[reason]}]`,
    );

    const startCode = this._auth.startCode;

    this._auth.logOut();
    this._state.reset();

    this._initialized = false;

    const url = this.getExitUrl(reason);
    this._redirect.redirect(url, startCode);
  }

  /**
   * Initializes the assessment and sets the current assessment state.
   */
  async initialize(): Promise<void> {
    if (this._initialized) {
      this._logger.debug('Assessment was already initialized.');
      return;
    }

    // Switch the flag
    this._initialized = true;

    this._logger.debug(
      'Assessment is starting - will now try to determine the state.',
    );

    const initialState = this.getInitialAssessmentState();

    this._logger.debug(
      `Assessment state detected as [${AssessmentState[initialState]}]. The assessment will now be redirected.`,
    );

    // Guard against entering complete state when we are not complete.
    // This should be an edge case, however, if server indicates not complete
    // ,and we are positioned in complete state we must submit.
    if (
      initialState === AssessmentState.Complete &&
      !this._client.data.isComplete
    ) {
      this._logger.info(
        'Assessment was detected in complete state, however, the server indicate incomplete assessment. The assessment will be submitted now.',
      );
      await this.finalize(false);
    }

    this._state.setState(initialState);
  }

  /**
   * Finalizes the assessment and submits answers for scoring
   * @returns A Promise that resolves to void.
   */
  async finalize(redirectToComplete: boolean = true): Promise<void> {
    // Store the state of progress tracking and stop it
    const isProgressTrackingEnabled = this._progressStore.isTrackingEnabled;
    this._progressStore.stopProgressTracking();

    // Show the spinner modal and try to submit the answers
    const modal = this._modal.alert(SubmitSpinnerComponent);
    const result = await this.submitAnswers();

    modal.close();

    // If the answers were, and we should redirect then do it
    if (result && redirectToComplete) {
      this._state.setState(AssessmentState.Complete);
      return;
    }

    // If answers were submitted and no redirect was requested then do nothing
    if (result) return;

    // If we failed to submit then show error modal
    // and retry after click
    if (isProgressTrackingEnabled) {
      this._progressStore.startProgressTracking();
    }

    await this._modal.error(SubmitErrorComponent, 'Results Not Sent', {
      confirmText: 'Try Again',
    }).result;

    await this.finalize(redirectToComplete);
  }

  private getInitialAssessmentState(): AssessmentState {
    // If there is only single content group then we are complete
    if (this._content.contentGroups.length === 1) {
      return AssessmentState.Complete;
    }

    // Progress to pointer to the next item we can enter if needed
    this.progressCurrentItemToAllowedItem();

    if (this._presentation.current.contentGroup.isCompleteGroup) {
      return AssessmentState.Complete;
    }

    if (
      this._presentation.current.isFirstInAssessment ||
      this._sourceData.getBoolean(QueryStringKey.SkipResume)
    ) {
      return AssessmentState.InProgress;
    }

    return AssessmentState.Resume;
  }

  private progressCurrentItemToAllowedItem(): void {
    // If the current item can be entered then there is nothing to do
    if (this.canEnterCurrentItemItem()) return;

    // If all above failed go to the next item and run
    // this method again.
    this._presentation.goToNext();
    this.progressCurrentItemToAllowedItem();
  }

  private canEnterCurrentItemItem(): boolean {
    const progressForItem = this._progress.currentItemTracker;
    const timer = this._timer.timerForCurrentGroup;
    const hasViewsRemaining =
      this._presentation.current.presentationItem.maxViewsPermitted === 0 ||
      this._presentation.current.presentationItem.maxViewsPermitted >
        progressForItem.shownToParticipantCount;
    const hasTimeRemaining = !timer || !timer.isExpired;

    // We can enter current item if there are views remaining AND there is no timer or the timer is not expired
    return hasViewsRemaining && hasTimeRemaining;
  }

  private async submitAnswers(): Promise<boolean> {
    const progress = this._progressStore.progressForAll();

    try {
      this._logger.debug(
        'Submitting answers to the server for scoring.',
        progress,
      );

      await this._requester.post<void>('content').send(progress);

      return true;
    } catch (error) {
      this._logger.error('Failed to submit answers for the assessment.', error);

      return false;
    }
  }

  private getExitUrl(reason: AssessmentExitReason): string {
    switch (reason) {
      case AssessmentExitReason.Error:
        return '/error';
      case AssessmentExitReason.InactivityTimeout:
        return '/inactivity-timeout';
      case AssessmentExitReason.SessionExpired:
        return '/session-expired';
      default:
        return '/';
    }
  }
}
