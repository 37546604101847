import { Injectable, Optional } from '@angular/core';
import { Logger } from '@compass/logging';

/**
 * EphemeralStorageService is a service for storing data in the browser's ephemeral storage.
 * Data stored using this service is not persistent and will be cleared when the browser session ends.
 */
@Injectable({
  providedIn: 'root',
})
export class EphemeralStorageService {
  private readonly _storageObject = this.initStorageObject();

  constructor(@Optional() private readonly _logger?: Logger) {}

  /**
   * Stores a value in the storage object using the specified key.
   *
   * @param {string} key - The key used to identify the value in the storage object.
   * @param {unknown} value - The value to be stored.
   * @return {void}
   */
  store(key: string, value: unknown): void {
    this._storageObject[key] = value;

    this._logger?.debug(
      `Data was stored in ephemeral storage with key [${key}].`,
      value,
    );
  }

  /**
   * Deletes the value associated with the given key from the storage object.
   *
   * @param {string} key - The key of the value to be deleted from the storage object.
   * @return {void}
   */
  delete(key: string): void {
    delete this._storageObject[key];
  }

  private initStorageObject(): Record<string, unknown> {
    // Set the object if not set already...
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.$compass = window.$compass || {};
    // And return it
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return window.$compass;
  }
}
