<div class="d-flex align-items-center flex-fill header-content">
  <cp-fa-icon *ngIf="dialogStyle === 'danger'"> circle-exclamation </cp-fa-icon>
  <cp-fa-icon *ngIf="dialogStyle === 'success'"> circle-check </cp-fa-icon>
  <cp-fa-icon *ngIf="dialogStyle === 'info'"> circle-info </cp-fa-icon>

  <ng-container *ngIf="isSimpleTitle">
    {{ title }}
  </ng-container>

  <ng-container *ngIf="!isSimpleTitle">
    <ng-container
      *ngTemplateOutlet="$any(title); context: titleContext ?? {}" />
  </ng-container>
</div>

<button *ngIf="showCloseButton" class="btn" (click)="closeClicked.emit()">
  &times;
</button>
