<div class="progress">
  <div
    class="progress-bar progress-bar-striped"
    role="progressbar"
    [style.width]="sanitizedProgress + '%'"
    [attr.aria-valuenow]="sanitizedProgress"
    aria-valuemin="0"
    aria-valuemax="100">
    {{ sanitizedProgress }}%
  </div>
</div>
