import { Injectable } from '@angular/core';
import { Requester } from '@compass/http';
import { Logger } from '@compass/logging';
import { ContentResponse } from '../responses/content.response';

@Injectable({
  providedIn: 'root',
})
export class ContentClient {
  private _contentResult?: ContentResponse;

  get data(): ContentResponse {
    this.throwExceptionIfContentNotLoaded();

    return this._contentResult!;
  }

  get hasContent(): boolean {
    return this._contentResult !== undefined;
  }

  constructor(
    private readonly _requester: Requester,
    private readonly _logger: Logger,
  ) {}

  async load(): Promise<void> {
    const response = await this._requester
      .get<ContentResponse>('content')
      .send();

    if (!response.isSuccess) {
      this._logger.error(
        'Failed to load content, because an error occurred.',
        response.errors,
      );
      return;
    }

    // If no data is returned OR no content is returned we are not
    // in a valid state - do nothing and let it error out.
    if (!response.data || response.data.content.length === 0) {
      this._logger.debug(
        'Failed to load content, because the server returned no content.',
      );
      return;
    }

    this._logger.debug(
      'Assessment data was received from the server.',
      response.data,
    );

    this._contentResult = response.data;
  }

  private throwExceptionIfContentNotLoaded(): void {
    if (this._contentResult) return;

    throw new Error(
      'Content was accessed before it was loaded. ContentService should only be used in authenticated pages after "load()" has been called.',
    );
  }
}
