import { Injectable } from '@angular/core';
import { ParticipantTermsComponent } from '../../components/participant-terms/participant-terms.component';

/**
 * Service class for managing terms and conditions
 *
 * @class
 * @injectable
 */
@Injectable({ providedIn: 'root' })
export class TermsAndConditionsService {
  private _conditions?: ParticipantTermsComponent;

  /**
   * Resets the accepted status with new conditions
   *
   * @returns {void}
   */
  reset(conditions?: ParticipantTermsComponent): void {
    this._conditions = conditions;
  }

  /**
   * Sets the accepted status
   */
  confirmTerms(): Promise<boolean> {
    // If there are no conditions set then we implicitly
    // accept no conditions.
    if (!this._conditions) {
      return Promise.resolve(true);
    }

    return this._conditions.request();
  }
}
