import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '@compass/ui';

@Component({
  selector: 'cp-par-submit-spinner',
  standalone: true,
  imports: [CommonModule, SpinnerComponent],
  templateUrl: './submit-spinner.component.html',
})
export class SubmitSpinnerComponent {}
