/**
 * Service for managing data that the assessment was initialized with.
 */
export class SourceDataService {
  private readonly _map: Map<string, unknown>;

  constructor(sourceData: [key: string, value: unknown][]) {
    this._map = new Map(sourceData);
  }

  get(key: string): unknown {
    return this._map.get(key);
  }

  getBoolean(key: string): boolean {
    const value = this.get(key);

    if (typeof value === 'boolean') {
      return value;
    }

    if (typeof value === 'string') {
      return value.toLowerCase() === 'true' || value.toLowerCase() === '1';
    }

    if (typeof value === 'number') {
      return value === 1;
    }

    return value !== undefined;
  }

  /**
   * Creates {@link SourceDataService} from query string values
   *
   * @param {string} queryString - The query string to be converted.
   * @returns {SourceDataService} - The SourceDataService object created from the query string.
   */
  static fromQueryString(queryString: string): SourceDataService {
    const params = new URLSearchParams(queryString);
    const sourceData: [string, unknown][] = [];

    params.forEach((value, key) => {
      sourceData.push([key, value]);
    });

    return new SourceDataService(sourceData);
  }
}
