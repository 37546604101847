import { Injectable } from '@angular/core';
import { ContentClient } from '../../clients/content.client';
import { ContentGroup } from '../../models/assessment/content-group';
import { ContentGroupTimer } from '../../models/assessment/content-group-timer';
import { filterNotNull, unique } from '@compass/helpers';
import { AssessmentRevision } from '../../models/assessment/assessment-revision';

@Injectable({
  providedIn: 'root',
})
export class AssessmentContentService {
  private readonly _timers: ContentGroupTimer[];

  get contentGroups(): ContentGroup[] {
    return this._contentClient.data.content;
  }

  get onCompleteUrl(): string {
    return this._contentClient.data.onCompleteUrl;
  }

  get reportCode(): string {
    return this._contentClient.data.reportCode;
  }

  get timers(): ContentGroupTimer[] {
    return this._timers;
  }

  get assessmentRevision(): AssessmentRevision {
    return this._contentClient.data.revision;
  }

  constructor(private readonly _contentClient: ContentClient) {
    this._timers = unique(
      filterNotNull(_contentClient.data.content.map(c => c.timer)),
      t => t.sourceId,
    );
  }
}
