<div class="container-fluid p-0">
  <div class="row">
    <div
      class="col-auto"
      *ngIf="client.data.revision.inactivityTimerSeconds > 0">
      <button
        class="btn btn-danger"
        [class.btn-danger]="inactivity.enabled"
        [class.btn-primary]="!inactivity.enabled"
        (click)="toggleInactivity()">
        {{ inactivity.enabled ? 'Disable' : 'Enable' }}
      </button>
    </div>
    <div class="col-auto">
      <button class="btn btn-primary" (click)="inactivity.idle()">
        Trigger idle
      </button>
    </div>
    <div class="col-auto">
      <button class="btn btn-primary" (click)="inactivity.timeout()">
        Trigger timeout
      </button>
    </div>
  </div>
</div>
