import {
  Component,
  Input,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  HostListener,
  DoCheck,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cp-par-pan-zoom',
  standalone: true,
  templateUrl: './pan-zoom.component.html',
  styleUrls: ['./pan-zoom.component.scss'],
  imports: [CommonModule],
})
export class PanZoomComponent implements OnInit, AfterViewInit, DoCheck {
  @Input() src = '';
  @ViewChild('panZoom') panZoom!: ElementRef;

  displayImage = '';
  thumbWidth = 0;
  thumbHeight = 0;
  zoomWidthProportionFactor = 0;
  zoomHeightProportionFactor = 0;
  widthProportion = 1;
  originalImageArray?: string[];
  thumbImage = new Image();
  zoomInstructions = '';
  paddingWidthOffset = 0;
  paddingHeightOffset = 0;
  isUrl = false;

  @Input() panZoomPercentageThreshold = 0.25;

  @HostListener('window:resize')
  onResize(): void {
    this.evaluateAndResizeImgSrc();
  }

  ngOnInit(): void {
    // checks if image comes from URL
    if (new URL(this.src).protocol === 'https:') {
      this.isUrl = true;
      this.originalImageArray = [this.src];
      return;
    }
    // user regex match to remove \\ chars from image src
    this.originalImageArray =
      this.src.match(/[^\\"]*data*[^\\]*/g) ?? undefined;
  }

  ngAfterViewInit(): void {
    if (this.originalImageArray) {
      this.evaluateAndResizeImgSrc();
      this.panZoom.nativeElement.addEventListener(
        'mousemove',
        (e: MouseEvent) => {
          this.setCoordinates(this.panZoom.nativeElement, e.offsetX, e.offsetY);
        },
      );
    }
  }

  ngDoCheck(): void {
    this.zoomInstructions = /Mobi|Android/i.test(navigator.userAgent)
      ? 'Touch inside the image to zoom'
      : 'Move your cursor inside the image to zoom';
  }

  evaluateAndResizeImgSrc(): void {
    if (!this.originalImageArray?.length) return;

    const canvas = document.createElement('canvas'); // use canvas in order to create resizable image from original
    const canvasContext = canvas.getContext('2d'); // context to redraw image
    const originalImage = new Image();

    let screenWidth = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0,
    );
    let screenHeight = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0,
    );
    const parentWidth =
      this.panZoom.nativeElement.parentElement?.parentElement?.parentElement
        ?.clientWidth;
    const parentHeight =
      this.panZoom.nativeElement.parentElement?.parentElement?.parentElement
        ?.clientHeight;
    screenWidth = parentWidth || screenWidth;
    screenHeight = parentHeight || screenHeight;

    this.paddingWidthOffset = this.getPaddingOffset(screenWidth, 0.95);
    this.paddingHeightOffset = this.getPaddingOffset(screenHeight, 0.95);

    originalImage.src = this.originalImageArray[0]; // originalImageArray is a result of regex match on base64 data stream
    originalImage.onload = () => {
      this.widthProportion =
        originalImage.width > screenWidth
          ? screenWidth / originalImage.width
          : 1;
      canvas.width =
        originalImage.width > screenWidth ? screenWidth : originalImage.width;
      canvas.height = originalImage.height * this.widthProportion;
      this.thumbWidth = canvas.width;
      this.thumbHeight = canvas.height;

      // these values represent the multiplication factor for x and y positioning when zoom image is displayed
      this.zoomHeightProportionFactor = this.getZoomProportionFactor(
        originalImage.height,
        this.thumbHeight,
      );
      this.zoomWidthProportionFactor = this.getZoomProportionFactor(
        originalImage.width,
        this.thumbWidth,
      );

      if (canvasContext) {
        // redraw original image to dimensions in relation to screen size
        canvasContext.drawImage(
          originalImage,
          0,
          0,
          originalImage.width,
          originalImage.height,
          0,
          0,
          canvas.width,
          canvas.height,
        );
      }

      this.thumbImage.src = this.isUrl
        ? this.originalImageArray![0]
        : canvas.toDataURL();
      this.thumbImage.onload = () => {
        this.displayImage = this.thumbImage.src;
      };
    };
  }

  setCoordinates(element: HTMLElement, x: number, y: number): void {
    if (!(element.children[0] instanceof HTMLElement)) return;

    if (this.enablePanZoom()) {
      x = this.setOffsetPosition(this.thumbWidth, x, this.paddingWidthOffset);
      y = this.setOffsetPosition(this.thumbHeight, y, this.paddingHeightOffset);

      if (x <= this.thumbWidth && y <= this.thumbHeight && x > 0 && y > 0) {
        element.children[0].style.backgroundPositionX = `${-(
          x * this.zoomWidthProportionFactor
        )}px`;
        element.children[0].style.backgroundPositionY = `${-(
          y * this.zoomHeightProportionFactor
        )}px`;
      }
    }
  }

  setTouchCoordinates(event: TouchEvent): void {
    event.preventDefault();
    const rect = this.panZoom.nativeElement.getBoundingClientRect();
    const xCoordinate = event.touches[0].clientX - rect.left;
    const yCoordinate = event.touches[0].clientY - rect.top;
    this.setCoordinates(this.panZoom.nativeElement, xCoordinate, yCoordinate);
  }

  onTouchMove(event: TouchEvent): void {
    this.setTouchCoordinates(event);
  }

  onTouchStart(event: TouchEvent): void {
    this.showPanZoom();
    setTimeout(() => {
      this.setTouchCoordinates(event);
    }, 1);
  }

  onTouchEnd(event: TouchEvent): void {
    event.preventDefault();
    this.showThumbnail();
  }

  showPanZoom(): void {
    if (this.enablePanZoom() && this.originalImageArray) {
      this.displayImage = this.originalImageArray[0];
    }
  }

  showThumbnail(): void {
    this.displayImage = this.thumbImage.src;
  }

  getZoomProportionFactor(fullLength: number, thumbLength: number): number {
    return fullLength / thumbLength - 1;
  }

  getPaddingOffset(dimension: number, nonOffsetPercent: number): number {
    return dimension - dimension * nonOffsetPercent;
  }

  setOffsetPosition(
    maxDimension: number,
    coordinate: number,
    offsetValue: number,
  ): number {
    if (coordinate >= maxDimension - offsetValue) {
      coordinate = maxDimension;
    } else if (coordinate <= offsetValue) {
      coordinate = 1;
    }
    return coordinate;
  }

  enablePanZoom(): boolean {
    return this.zoomWidthProportionFactor > this.panZoomPercentageThreshold;
  }
}
