import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { DoNotFocusDirective, FaIconComponent } from '@compass/ui';
import { CalculatorService } from '../../services/ui/calculator.service';
import { Subscription } from 'rxjs';
import { DeviceInfoService } from '@compass/environment';

@Component({
  selector: 'cp-par-calculator-button',
  standalone: true,
  imports: [CommonModule, NgbTooltip, DoNotFocusDirective, FaIconComponent],
  templateUrl: './calculator-button.component.html',
  styleUrls: ['./calculator-button.component.scss'],
})
export class CalculatorButtonComponent implements OnDestroy {
  private readonly _calculatorResultSub: Subscription;

  protected calculatorResult: string = '';

  constructor(
    protected readonly calculator: CalculatorService,
    protected readonly deviceInfo: DeviceInfoService,
  ) {
    this._calculatorResultSub = this.calculator.calculatorResult$.subscribe(
      result => (this.calculatorResult = result),
    );
  }

  ngOnDestroy(): void {
    this._calculatorResultSub.unsubscribe();
  }

  protected getCalculatorToolTip(): string {
    if (!this.calculatorResult || this.calculatorResult === '0') {
      return 'Open Calculator / Close Calculator';
    } else if (this.calculator.visible) {
      return 'Close Calculator, ' + this.calculatorResult;
    } else {
      return 'Open Calculator, ' + this.calculatorResult;
    }
  }
}
