<cp-vimeo
  (stateChanged)="playerStateChanged($event)"
  [src]="src"
  [playerDisableBack]="playerDisableBack"
  [playerDisableForward]="playerDisableForward"
  [playerDisablePause]="playerDisablePause"
  [afterCompleteHidePlayer]="afterCompleteHidePlayer"
  [showCaptions]="showCaptions" />

<ng-template #longLoadTemplate>
  <p>This page contains a video that is taking a very long time to load.</p>
  <p>This could be caused by a slow or offline network connection.</p>
  <p>
    You can either keep waiting for the video to load or try refreshing the page
    after checking your network connection. Your device must be connected to the
    Internet to load the video this page requires.
  </p>
</ng-template>
