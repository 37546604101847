import { Directive, ElementRef, HostListener } from '@angular/core';

/**
 * Directive that causes button to not retain focus after click.
 */
@Directive({
  selector: 'button[cpDoNotFocus]',
  standalone: true,
})
export class DoNotFocusDirective {
  private readonly _button: HTMLButtonElement;

  constructor(_elementRef: ElementRef) {
    this._button = _elementRef.nativeElement;
  }

  @HostListener('click')
  onClick(): void {
    this._button.blur();
  }
}
