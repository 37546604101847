import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'cp-par-progress-bar',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
  /**
   * Progress of the progress bar.
   * Value between 0 and 1.
   */
  @Input()
  progress: number = 0;

  protected get sanitizedProgress(): number {
    if (this.progress < 0) return 0;
    if (this.progress > 1) return 1;

    return Math.round(this.progress * 100);
  }
}
