import {
  AfterViewInit,
  booleanAttribute,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { VideoStatus, VimeoPlayerComponent } from '@compass/video';
import { VideoService } from '../../services/ui/video.service';
import { ModalService } from '@compass/notifications';

@Component({
  selector: 'cp-par-vimeo',
  templateUrl: './participant-vimeo-player.component.html',
  imports: [VimeoPlayerComponent],
  standalone: true,
})
export class ParticipantVimeoPlayerComponent implements OnInit, AfterViewInit {
  @Input() src: string = '';

  @Input({ transform: booleanAttribute }) playerDisableBack: boolean = false;

  @Input({ transform: booleanAttribute }) playerDisableForward: boolean = false;

  @Input({ transform: booleanAttribute }) playerDisablePause: boolean = false;

  @Input({ transform: booleanAttribute }) afterCompleteHidePlayer: boolean =
    false;

  @Input({ transform: booleanAttribute }) showCaptions: boolean = true;

  @Input({ transform: booleanAttribute })
  beforeCompleteDisableBackNav: boolean = false;

  @Input({ transform: booleanAttribute })
  beforeCompleteDisableNextNav: boolean = false;

  @Input({ transform: booleanAttribute }) beforeCompleteHideChoices: boolean =
    false;

  @Input({ transform: booleanAttribute }) afterCompleteMoveNext: boolean =
    false;

  @ViewChild('longLoadTemplate', { read: TemplateRef })
  longLoadRef!: TemplateRef<unknown>;

  private _videoStatus?: VideoStatus;

  constructor(
    private readonly _videoService: VideoService,
    private readonly _modalService: ModalService,
  ) {}

  ngOnInit(): void {
    this._videoService.init(
      this.beforeCompleteDisableNextNav,
      this.beforeCompleteDisableBackNav,
      this.beforeCompleteHideChoices,
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this._videoStatus?.isReady || this._videoStatus?.failedToLoad) return;

      this._modalService.show(
        this.longLoadRef,
        'This video is taking a long time to load.',
        {
          showConfirm: true,
          showCancel: false,
          showCloseButton: false,
          confirmText: 'Keep Waiting',
        },
      );
    }, 5000);
  }

  protected async playerStateChanged(videoStatus: VideoStatus): Promise<void> {
    if (videoStatus.completed)
      this._videoService.videoCompleted(this.afterCompleteMoveNext);

    if (videoStatus.loaded && this._videoStatus && !this._videoStatus.loaded) {
      this._videoService.videoLoaded();
    }

    if (videoStatus.failedToLoad) {
      this._modalService.error(
        'An error occurred while attempting to play the video. Refresh the page or contact customer support if the problem persists.',
        'Video could not be loaded',
      );
    }

    this._videoStatus = { ...videoStatus };
  }
}
