import { Injectable, OnDestroy } from '@angular/core';
import { ContentGroup } from '../../models/assessment/content-group';
import { PresentationItemTracker } from '../../models/presentation-item/presentation-item-tracker';
import { Logger } from '@compass/logging';
import { PresentationItemState } from '../../models/assessment/presentation-item-state';
import { ContentClient } from '../../clients/content.client';
import {
  PresentationItemChangeArgs,
  PresentationService,
} from './presentation.service';

enum ElementIncrementsViewCount {
  vimeo = 'cp-vimeo',
}

export interface GroupProgress {
  readonly progressId: string;
  readonly progress: number;
  readonly shown: number;
  readonly total: number;
}

const GLOBAL_GROUP = 'global';

@Injectable({
  providedIn: 'root',
})
export class ProgressService implements OnDestroy {
  private readonly _itemTrackersForGroup = new Map<
    string,
    PresentationItemTracker[]
  >();
  private readonly _presentationItemChangeSub =
    this._presentation.presentationItemChange$.subscribe(
      this.onPresentationItemChange.bind(this),
    );

  private _progressForCurrentGroup: GroupProgress;
  private _currentItemTracker: PresentationItemTracker;

  get progressForCurrentGroup(): GroupProgress {
    return this._progressForCurrentGroup;
  }

  get currentItemTracker(): PresentationItemTracker {
    return this._currentItemTracker;
  }

  constructor(
    contentClient: ContentClient,
    private readonly _logger: Logger,
    private readonly _presentation: PresentationService,
  ) {
    this.init(
      contentClient.data.content,
      contentClient.data.progress?.presentationItemStates,
    );

    this._progressForCurrentGroup = this.getProgressForGroup(
      this._presentation.current.contentGroup.progressBarSourceId,
    );
    this._currentItemTracker = this.getItemProgress(
      this._presentation.current.presentationItem.questionId,
    );
  }

  ngOnDestroy(): void {
    this._presentationItemChangeSub.unsubscribe();
  }

  getItemProgress(questionId: string): PresentationItemTracker {
    const question = this._itemTrackersForGroup
      .get(GLOBAL_GROUP)!
      .find(t => t.questionId === questionId);

    if (!question) {
      this._logger.warn(
        `No tracker was found for question [${questionId}]. A tracker with no effect will be used.`,
      );
      return this.getNullTracker();
    }

    return question;
  }

  updateProgress(): void {
    this._progressForCurrentGroup = this.getProgressForGroup(
      this._presentation.current.contentGroup.progressBarSourceId,
    );
  }

  private getProgressForGroup(
    progressGroupId: string = GLOBAL_GROUP,
  ): GroupProgress {
    const group = this._itemTrackersForGroup.get(progressGroupId);

    if (!group || group.length === 0) {
      this._logger.warn(
        `No progress was found for group [${progressGroupId}].`,
      );
      return { progressId: 'N/A', progress: 0, total: 0, shown: 0 };
    }

    const shown = group.filter(q => q.shownToParticipantCount > 0).length;

    return {
      progressId: progressGroupId,
      progress: shown / group.length,
      shown,
      total: group.length,
    };
  }

  private getNullTracker(): PresentationItemTracker {
    return new PresentationItemTracker('');
  }

  private onPresentationItemChange(change: PresentationItemChangeArgs): void {
    this._currentItemTracker.stopTracking();

    this._currentItemTracker = this.getItemProgress(change.newItem.questionId);

    this._currentItemTracker.startTracking(
      this.canIncrementShownCount(change.newItem.contentBody),
    );

    this.updateProgress();
  }

  canIncrementShownCount(contentBody: string): boolean {
    const enumValues = Object.values(ElementIncrementsViewCount);
    return !enumValues.some(enumVal =>
      new RegExp(enumVal, 'g').test(contentBody),
    );
  }

  private init(
    contentGroups: ContentGroup[],
    itemStates?: PresentationItemState[],
  ): void {
    // Create global progress group with empty ID
    const globalTrackers: PresentationItemTracker[] = [];

    for (const contentGroup of contentGroups) {
      for (const item of contentGroup.presentationItems) {
        const itemState = itemStates?.find(
          is => is.questionId === item.questionId,
        );
        const tracker = new PresentationItemTracker(
          item.questionId,
          itemState?.shownToCandidateCount,
          itemState?.secondsVisibleCount,
        );

        // Add tracker to global group
        globalTrackers.push(tracker);
        // Add tracker to its progress group
        if (this._itemTrackersForGroup.has(contentGroup.progressBarSourceId)) {
          this._itemTrackersForGroup
            .get(contentGroup.progressBarSourceId)!
            .push(tracker);
        } else {
          this._itemTrackersForGroup.set(contentGroup.progressBarSourceId, [
            tracker,
          ]);
        }
      }
    }

    this._itemTrackersForGroup.set(GLOBAL_GROUP, globalTrackers);
  }
}
