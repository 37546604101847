import {
  Component,
  ElementRef,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { CalculatorService } from '../../services/ui/calculator.service';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cp-par-calculator-copy-button',
  templateUrl: './calculator-copy-button.component.html',
  styleUrls: ['./calculator-copy-button.component.scss'],
  imports: [NgbTooltip],
  standalone: true,
})
export class CalculatorCopyButtonComponent {
  @ViewChild('kbd')
  kbdElement!: ElementRef;

  @Input()
  value?: string;

  constructor(private readonly _calculatorService: CalculatorService) {}

  @HostListener('click')
  onClick(): void {
    const valueToSet = this.value ?? this.kbdElement.nativeElement.innerText;

    this._calculatorService.setNewResult(this.parseNumber(valueToSet));
  }

  private parseNumber(value: string): number {
    // If there is no value then return 0
    if (!value) return 0;
    // If the value is percentage then convert it and return
    if (/^-?(\\d*|\\d*\\.\\d*)%$/.exec(value)) {
      return parseFloat(value) / 100;
    }
    // Replace any characters that are not allowed
    value = value.replace(/[^\d.-]/g, '');

    return !value ? 0 : parseFloat(value);
  }
}
