<!-- @sonar-disable -->

<ng-container *ngIf="groupProgress.progressId">
  <table class="table table-sm table-striped" role="presentation">
    <tbody>
      <!-- Progress data -->
      <tr>
        <td>Progress group ID</td>
        <td>{{ groupProgress.progressId }}</td>
      </tr>

      <tr>
        <td>Progress</td>
        <td>{{ groupProgress.progress | percent: '1.1-3' }}</td>
      </tr>

      <tr>
        <td>Items viewed in progress group</td>
        <td>{{ groupProgress.shown }}</td>
      </tr>

      <tr>
        <td>Total items in progress group</td>
        <td>{{ groupProgress.total }}</td>
      </tr>
    </tbody>
  </table>
</ng-container>

<ng-container *ngIf="!groupProgress.progressId">
  This content group does not track progress
</ng-container>
