<div class="d-flex flex-column flex-md-row align-items-center">
  <svg viewBox="0 0 30 30">
    <!--
    Radius: (r of second circle) * 1.85
    -->
    <circle
      cx="15"
      cy="15"
      r="12.95"
      stroke="#c7c7c7"
      stroke-width="1"
      fill="none" />
    <!--
    Stroke dasharray: 2 * R * PI
    Stroke width: 2 * R
    -->
    <circle
      stroke-dasharray="43.98"
      [attr.stroke-dashoffset]="elapsedPercentage * 43.98"
      cx="15"
      cy="15"
      r="7"
      fill="none"
      stroke-width="14"
      [attr.stroke]="'hsl(' + 100 * (1 - elapsedPercentage) + ', 70%, 50%)'" />
  </svg>

  <span class="ms-md-2 text-secondary">
    {{ totalSeconds - elapsedSeconds | time }}
  </span>
</div>
