import { Component, HostBinding, HostListener } from '@angular/core';
import { ObjectIndex } from '@compass/types';
import { getCssClasses } from '@compass/helpers';
import {
  ApplicationEnvironment,
  DeviceInfoService,
} from '@compass/environment';

@Component({
  selector: 'cp-par-root',
  template: '<router-outlet/>',
})
export class AppComponent {
  @HostBinding('class')
  get class(): ObjectIndex<boolean> {
    return getCssClasses(
      {
        cssClass: this.getDeviceClass(),
        prefix: 'v',
      },
      {
        cssClass: 'user-select-none',
        condition: this._appEnv.isProduction,
      },
    );
  }

  constructor(
    private readonly _appEnv: ApplicationEnvironment,
    private readonly _deviceInfo: DeviceInfoService,
  ) {}

  @HostListener('cut', ['$event'])
  @HostListener('copy', ['$event'])
  protected preventDefault(data: Event): void {
    if (!this._appEnv.isProduction) return;

    data.preventDefault();
  }

  @HostListener('contextmenu')
  protected onContextMenu(): boolean {
    // On context menu return false if we are in production
    // to prevent context menu showing up.
    return !this._appEnv.isProduction;
  }

  private getDeviceClass(): string {
    if (this._deviceInfo.device.isMobile) return 'mobile';
    if (this._deviceInfo.device.isTablet) return 'tablet';

    return 'desktop';
  }
}
