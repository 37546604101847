/**
 * Represents a configuration object for a dialog with confirmation button.
 */
export type ConfirmButtonDialogConfig = {
  /**
   * Indicates whether to show the confirm button.
   *
   * @type {boolean}
   */
  showConfirm: boolean;
  /**
   * The text of a confirmation button.
   *
   * @type {string}
   */
  confirmText: string;
  /**
   * A function that checks whether a confirm button is disabled or not.
   * If the callback returns `true` if the confirm button is disabled, otherwise returns `false`.
   *
   * @type {() => boolean} A callback that returns boolean value
   */
  confirmDisabled?: () => boolean;
  /**
   * A callback function that is executed before the confirmation action is performed.
   * It can return a boolean value or a Promise that resolves to a boolean value.
   * If the returned value is false or a Promise that resolves to false,
   * the confirmation action is cancelled.
   *
   * @returns {boolean|Promise<boolean>} Returns a boolean value or a Promise that resolves to a boolean value.
   */
  beforeConfirm?: () => boolean | Promise<boolean>;
};

/**
 * Represents the configuration options for closing a dialog.
 */
export type DialogCloseConfig = {
  /**
   * Indicates whether the dialog closes on ESC key press.
   *
   * @type {boolean}
   */
  closeOnEscape: boolean;
  /**
   * Indicates whether the dialog closes on backdrop click.
   *
   * @type {boolean}
   */
  closeOnBackdropClick: boolean;

  /**
   * Indicates whether the close button should be displayed.
   *
   * @type {boolean}
   */
  showCloseButton: boolean;
};

/**
 * Represents the configuration options dialog cancel button.
 */
export type CancelButtonDialogConfig = {
  /**
   * Indicates whether to show the cancel button.
   *
   * @type {boolean}
   */
  showCancel: boolean;
  /**
   * The text of a cancel button.
   *
   * @type {string}
   */
  cancelText: string;
};

/**
 * Represents the configuration for the context of a dialog template.
 */
export type DialogTemplateContextConfig<TContent, TTitle> = {
  /**
   * Represents the context of content.
   */
  contentContext?: Partial<TContent>;
  /**
   * Represents the context for a title.
   */
  titleContext?: Partial<TTitle>;
};

/**
 * Represents a configuration for the display of a dialog.
 */
export type DialogDisplayConfig = {
  /**
   * Represents a boolean variable indicating whether the dialog is in fullscreen mode or not.
   *
   * @type {boolean}
   */
  fullscreen: boolean;

  /**
   * Indicates whether HTML is allowed in string content.
   *
   * @type {boolean}
   */
  allowHtml: boolean;
};

/**
 * Represents the options for configuring the buttons in a dialog.
 */
export type DialogConfig = ConfirmButtonDialogConfig &
  CancelButtonDialogConfig &
  DialogCloseConfig &
  DialogDisplayConfig;

/**
 * Represents the default options for dialog buttons.
 */
export const defaultDialogConfiguration: DialogConfig = {
  showCancel: true,
  cancelText: 'Cancel',
  showConfirm: true,
  confirmText: 'OK',
  closeOnEscape: true,
  closeOnBackdropClick: true,
  showCloseButton: true,
  fullscreen: false,
  allowHtml: false,
};
