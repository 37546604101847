import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PresentationService } from '../../../services/assessment/presentation.service';
import { AssessmentContentService } from '../../../services/assessment/assessment-content.service';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'cp-par-debug-navigation',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './debug-navigation.component.html',
})
export class DebugNavigationComponent {
  constructor(
    protected readonly presentation: PresentationService,
    protected readonly content: AssessmentContentService,
  ) {}

  protected goToNextGroup(): void {
    const nextGroup = this.presentation.current.next?.contentGroup;

    if (!nextGroup) return;

    this.presentation.goToGroup(nextGroup.sourceId);
  }

  protected goToPreviousGroup(): void {
    const previousGroup = this.presentation.current.previous?.contentGroup;

    if (!previousGroup) return;

    this.presentation.goToGroup(previousGroup.sourceId);
  }
}
