import {
  Component,
  effect,
  inject,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PresentationService } from '../../services/assessment/presentation.service';
import {
  AssessmentState,
  AssessmentStateService,
} from '../../services/assessment/assessment-state.service';
import { TimerService } from '../../services/assessment/timer.service';
import { ContentGroupTimer } from '../../models/assessment/content-group-timer';
import { AssessmentContentService } from '../../services/assessment/assessment-content.service';
import { PresentationItemType } from '../../models/assessment/presentation-item-type';
import { SelectionService } from '../../services/assessment/selection.service';
import { IdentityService } from '../../services/ui/identity.service';
import { ModalService } from '@compass/notifications';
import { FaIconComponent } from '@compass/ui';
import { HotkeyDirective } from '../../directives/hotkey.directive';
import { KeyboardKey } from '../../services/ui/keyboard.service';
import { SupportService } from '../../services/ui/support.service';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { AssessmentService } from '../../services/assessment/assessment.service';
import { TermsAndConditionsService } from '../../services/ui/terms-and-conditions.service';
import { VideoService } from '../../services/ui/video.service';

@Component({
  selector: 'cp-par-next-button',
  standalone: true,
  imports: [CommonModule, FaIconComponent, HotkeyDirective, NgbTooltip],
  templateUrl: './next-button.component.html',
})
export class NextButtonComponent implements OnDestroy {
  private readonly _expiredTimerSub = inject(
    TimerService,
  ).timerExpired$.subscribe(this.timerExpired.bind(this));

  private _expiredLabel =
    this._presentation.current.contentGroup.timer
      ?.expirationNavigationNextLabel ?? '';

  private _disableUntilVideoCompletion: boolean = false;

  protected readonly KeyboardKey = KeyboardKey;

  protected isBusy: boolean = false;

  protected get showButton(): boolean {
    switch (this._state.currentState) {
      case AssessmentState.Complete:
      case AssessmentState.InProgress:
        return !this._presentation.current.isLastInAssessment;
      default:
        return true;
    }
  }

  protected get buttonText(): string {
    switch (this._state.currentState) {
      case AssessmentState.Complete:
      case AssessmentState.InProgress:
        return this._presentation.current.presentationItem.navigationNextLabel;
      case AssessmentState.Support:
        return 'Submit';
      case AssessmentState.Resume:
        return this._content.assessmentRevision.resumeBodyNavigationNextLabel;
      case AssessmentState.Expired:
        return this._expiredLabel;
      default:
        return '';
    }
  }

  protected get enabled(): boolean {
    if (this._state.currentState === AssessmentState.Support) {
      return this._support.supportForm.valid;
    }

    if (this._state.currentState !== AssessmentState.InProgress) return true;

    switch (this._presentation.current.presentationItem.presentationItemType) {
      case PresentationItemType.Page:
        return this.canPageGoNext();
      case PresentationItemType.Question:
        return this.canQuestionGoNext();
      default:
        return false;
    }
  }

  protected get tooltipText(): string | undefined {
    if (this.enabled) return undefined;

    // When in support page and the form is invalid
    if (
      this._state.currentState === AssessmentState.Support &&
      this._support.supportForm.invalid
    ) {
      return 'Complete the form to request help';
    }

    // When in progress and showing page
    if (
      this._state.currentState === AssessmentState.InProgress &&
      this._presentation.current.presentationItem.presentationItemType ===
        PresentationItemType.Page
    ) {
      return 'Complete the form to continue';
    }

    // When in progress and showing question
    if (
      this._state.currentState === AssessmentState.InProgress &&
      this._presentation.current.presentationItem.presentationItemType ===
        PresentationItemType.Question
    ) {
      return 'Answer the current question to continue';
    }

    return undefined;
  }

  @ViewChild('supportFormSubmitted')
  supportFormSubmitted!: TemplateRef<unknown>;

  constructor(
    private readonly _presentation: PresentationService,
    private readonly _state: AssessmentStateService,
    private readonly _content: AssessmentContentService,
    private readonly _selection: SelectionService,
    private readonly _identity: IdentityService,
    private readonly _modal: ModalService,
    private readonly _support: SupportService,
    private readonly _assessment: AssessmentService,
    private readonly _termsAndConditions: TermsAndConditionsService,
    private readonly _videoService: VideoService,
  ) {
    effect(() => {
      this._disableUntilVideoCompletion =
        this._videoService.disableForwardNavigation();
    });
  }

  ngOnDestroy(): void {
    this._expiredTimerSub.unsubscribe();
  }

  protected async nextClick(): Promise<void> {
    if (!this.enabled) return;

    switch (this._state.currentState) {
      case AssessmentState.InProgress:
        if (await this._termsAndConditions.confirmTerms()) {
          await this.goToNextPresentationItem();
        }
        break;
      case AssessmentState.Complete:
        this._presentation.goToNext();
        break;
      case AssessmentState.Expired:
        this._state.setState(
          this._presentation.current.contentGroup.isCompleteGroup
            ? AssessmentState.Complete
            : AssessmentState.InProgress,
        );
        break;
      case AssessmentState.Resume:
        this._state.setState(AssessmentState.InProgress);
        break;
      case AssessmentState.Support:
        await this.submitSupportForm();
        break;
    }
  }

  private async submitSupportForm(): Promise<void> {
    this.isBusy = true;

    const result = await this._support.submitForm();

    this.isBusy = false;

    if (!result) {
      this._modal.error(
        'The support message could not be delivered to us. Please try again.',
        'Support request failed',
      );
      return;
    }

    const successModal = this._modal.success(
      this.supportFormSubmitted,
      'Support request received',
      {
        confirmText: 'Resume Assessment',
        contentContext: {
          $implicit: this._support.supportForm.value['email'],
        },
      },
    );

    if (await successModal.result) {
      this._state.goBack();
    }
  }

  private async goToNextPresentationItem(): Promise<void> {
    // If identity needs updating and the update fails, then
    // do not progress anywhere.
    if (!(await this.updateParticipantIdentity())) {
      return;
    }

    // If the next group is final group then try to submit answers
    if (
      this._presentation.current.isLastInGroup &&
      this._presentation.current.next.contentGroup?.isCompleteGroup
    ) {
      await this._assessment.finalize();
      return;
    }

    // If nothing else happened progress the item
    this._presentation.goToNext();
  }

  private async updateParticipantIdentity(): Promise<boolean> {
    this.isBusy = true;

    const result = await this._identity.submitChanges();

    if (!result) {
      this._modal.error(
        'An error occurred while updating your contact information. Please check the provided information and try again. If the problem persists, restart the assessment from the invitation you have received from your recruiter.',
        'Contact information not updated',
      );
    }

    this.isBusy = false;

    return result;
  }

  private timerExpired(timer: ContentGroupTimer): void {
    this._expiredLabel = timer.expirationNavigationNextLabel;
  }

  private canPageGoNext(): boolean {
    return !this._disableUntilVideoCompletion && this._identity.isValid();
  }

  private canQuestionGoNext(): boolean {
    const question = this._presentation.current.presentationItem;
    const numberOfItemsSelected = this._selection.getForQuestion(
      question.questionId,
    ).length;

    return (
      numberOfItemsSelected >= question.minimumResponses &&
      numberOfItemsSelected <= question.maximumResponses &&
      !this._disableUntilVideoCompletion
    );
  }
}
