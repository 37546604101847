import { Injectable, OnDestroy } from '@angular/core';
import {
  KeyboardAction,
  KeyboardKey,
  KeyboardService,
} from './keyboard.service';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HotkeyService implements OnDestroy {
  private readonly _keyPressSubscription: Subscription;
  private readonly _hotkeys: HotkeySubscription[] = [];

  constructor(keyboardService: KeyboardService) {
    this._keyPressSubscription = keyboardService.onKeyDown$.subscribe(
      this.onKeyPress.bind(this),
    );
  }

  ngOnDestroy(): void {
    this._keyPressSubscription.unsubscribe();
  }

  add(
    key: KeyboardKey,
    action: () => void,
    requirements?: HotkeyRequirements,
  ): number {
    requirements = this.sanitizeRequirements(requirements);

    let subscription = this.getSubscriptionForKey(
      key,
      requirements.requireCtrl,
      requirements.requireAlt,
      requirements.requireShift,
    );

    if (subscription) {
      this.remove(subscription.id);
    }

    subscription = {
      id: this.getId(),
      key,
      requirements,
      action,
    };

    this._hotkeys.push(subscription);
    // Return the ID
    return subscription.id;
  }

  isAvailable(key: KeyboardKey, requirements?: HotkeyRequirements): boolean {
    return !this.getSubscriptionForKey(
      key,
      requirements?.requireCtrl,
      requirements?.requireAlt,
      requirements?.requireShift,
    );
  }

  remove(hotkeyId: number | number[]): void {
    if (!hotkeyId) return;
    const ids = Array.isArray(hotkeyId) ? hotkeyId : [hotkeyId];

    for (const id of ids) {
      const subscriptionIndex = this._hotkeys.findIndex(h => h.id === id);
      if (subscriptionIndex >= 0) {
        this._hotkeys.splice(subscriptionIndex, 1);
      }
    }
  }

  private onKeyPress(keyboardAction: KeyboardAction): void {
    // Do not activate hotkeys if we are in input
    if (keyboardAction.targetsInput()) return;

    const hotkeySubscription =
      this.getSubscriptionForKeyboardAction(keyboardAction);
    if (hotkeySubscription) {
      hotkeySubscription.action();
    }
  }

  private getSubscriptionForKeyboardAction(
    keyboardAction: KeyboardAction,
  ): HotkeySubscription | undefined {
    return this.getSubscriptionForKey(
      keyboardAction.key,
      keyboardAction.ctrl,
      keyboardAction.alt,
      keyboardAction.shift,
    );
  }

  private getSubscriptionForKey(
    key: KeyboardKey,
    requireCtrl?: boolean,
    requireAlt?: boolean,
    requireShift?: boolean,
  ): HotkeySubscription | undefined {
    requireCtrl ??= false;
    requireAlt ??= false;
    requireShift ??= false;

    const subscription = this._hotkeys.filter(
      h =>
        h.key === key &&
        h.requirements.requireCtrl === requireCtrl &&
        h.requirements.requireAlt === requireAlt &&
        h.requirements.requireShift === requireShift,
    );
    return subscription.length === 1 ? subscription[0] : undefined;
  }

  private getId(): number {
    return Math.round(Math.random() * 1000000);
  }

  private sanitizeRequirements(
    requirements?: HotkeyRequirements,
  ): HotkeyRequirements {
    return {
      requireCtrl: requirements?.requireCtrl ?? false,
      requireShift: requirements?.requireShift ?? false,
      requireAlt: requirements?.requireAlt ?? false,
    };
  }
}

export interface HotkeyRequirements {
  requireCtrl?: boolean;
  requireAlt?: boolean;
  requireShift?: boolean;
}

export interface HotkeySubscription {
  id: number;
  key: KeyboardKey;
  requirements: HotkeyRequirements;
  action: () => void;
}
