import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { Requester } from '@compass/http';
import { AppConfigurationService } from '../../services/security/app-configuration.service';
import { IframeRelayDirective } from '@compass/ui';
import { AssessmentContentService } from '../../services/assessment/assessment-content.service';

@Component({
  selector: 'cp-par-report',
  standalone: true,
  imports: [CommonModule, IframeRelayDirective],
  templateUrl: './report.component.html',
})
export class ReportComponent implements AfterViewInit, OnDestroy {
  private _iFrameResizeComponent: unknown;

  protected readonly reportUrl =
    this._domSanitizer.bypassSecurityTrustResourceUrl(
      this._appConfig.get().reportUrl + '/embedded',
    );

  protected loadFailed = false;

  @ViewChild('reportFrame')
  reportFrame!: ElementRef;

  @ViewChild(IframeRelayDirective)
  iframeRelay!: IframeRelayDirective;

  constructor(
    private readonly _appConfig: AppConfigurationService,
    private readonly _domSanitizer: DomSanitizer,
    private readonly _requester: Requester,
    private readonly _assessmentContentService: AssessmentContentService,
  ) {}

  async ngAfterViewInit(): Promise<void> {
    const iframe = this.reportFrame.nativeElement as HTMLIFrameElement;

    if (!iframe) return;

    // @ts-expect-error iFrameResize is external script...
    this._iFrameResizeComponent = window.iFrameResize(
      {
        checkOrigin: false,
        resizeFrom: 'child',
      },
      iframe,
    )[0];

    this.tryLoadReport();
  }

  ngOnDestroy(): void {
    // @ts-expect-error iFrameResizer is set in external script
    this._iFrameResizeComponent.iFrameResizer.close();
  }

  protected async tryLoadReport(): Promise<void> {
    this.loadFailed = false;

    const data = await this.getReportData();

    if (!data) {
      this.loadFailed = true;
      return;
    }

    this.iframeRelay.send({ data });
  }

  private async getReportData(): Promise<unknown> {
    const response = await this._requester
      .get('report')
      .addRouteSegments(this._assessmentContentService.reportCode)
      .send();

    return response.isSuccess ? response.data : undefined;
  }
}
