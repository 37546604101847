import { Injectable, OnDestroy } from '@angular/core';
import { fromEvent, merge, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class KeyboardService implements OnDestroy {
  private readonly _keysToPreventDefault: KeyboardKey[] = [
    KeyboardKey.ArrowUp,
    KeyboardKey.ArrowDown,
  ];
  private readonly _keyPressSub = merge(
    fromEvent(document, 'keydown'),
    fromEvent(document, 'keyup'),
  ).subscribe(this.keyPressed.bind(this));

  readonly onKeyUp$ = new Subject<KeyboardAction>();
  readonly onKeyDown$ = new Subject<KeyboardAction>();

  ngOnDestroy(): void {
    this._keyPressSub.unsubscribe();
  }

  private keyPressed(event: Event): void {
    if (!(event instanceof KeyboardEvent) || !event.code || !event.key) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const key = KeyboardKey[event.key];

    if (this._keysToPreventDefault.includes(key)) {
      event.preventDefault();
    }

    const action = new KeyboardAction(event);

    switch (event.type) {
      case 'keyup':
        this.onKeyUp$.next(action);
        break;
      case 'keydown':
        this.onKeyDown$.next(action);
        break;
    }
  }
}

export class KeyboardAction {
  readonly key: KeyboardKey;

  constructor(public readonly event: KeyboardEvent) {
    this.key = this.getKeyboardKey(event.code);
  }

  get ctrl(): boolean {
    return this.event.ctrlKey;
  }
  get alt(): boolean {
    return this.event.altKey;
  }
  get shift(): boolean {
    return this.event.shiftKey;
  }
  get keyText(): string {
    return this.event.key;
  }

  targetsInput(): boolean {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const targetName = this.event.target.localName?.toLowerCase();
    if (!targetName) return false;

    return targetName === 'input' || targetName === 'textarea';
  }

  private getKeyboardKey(key: string): KeyboardKey {
    if (key.length === 1) {
      key = key.toLowerCase();
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return KeyboardKey[key];
  }
}

export enum KeyboardKey {
  ArrowUp,
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  Slash,
  Space,
  Enter,
  KeyA,
  KeyB,
  KeyC,
  KeyD,
  KeyE,
  KeyF,
  KeyG,
  KeyH,
  KeyI,
  KeyJ,
  KeyK,
  KeyL,
  KeyM,
  KeyN,
  KeyO,
  KeyP,
  KeyQ,
  KeyR,
  KeyS,
  KeyT,
  KeyU,
  KeyV,
  KeyW,
  KeyX,
  KeyY,
  KeyZ,
  Numpad1,
  Numpad2,
  Numpad3,
  Numpad4,
  Numpad5,
  Numpad6,
  Numpad7,
  Numpad8,
  Numpad9,
  Numpad0,
  NumpadDivide,
  NumpadMultiply,
  NumpadSubtract,
  NumpadAdd,
  NumpadEnter,
  NumpadDecimal,
  Digit1,
  Digit2,
  Digit3,
  Digit4,
  Digit5,
  Digit6,
  Digit7,
  Digit8,
  Digit9,
  Digit0,
  Delete,
  Backspace,
  Minus,
  Equal,
  Period,
  Clear,
}
