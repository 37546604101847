<iframe
  #reportFrame
  cpIframeCommunicator
  class="w-100"
  [class.d-none]="loadFailed"
  [src]="reportUrl"
  title="Participant report"></iframe>

<div *ngIf="loadFailed" class="border border-danger text-center py-2 border-2">
  <p>Failed to load report. Click <strong>Retry</strong> to try again.</p>
  <button class="btn btn-primary" (click)="tryLoadReport()">Retry</button>
</div>
