import { QueryStringKey } from '../constants/query-string-key';

/**
 * Provides methods for start code manipulation.
 */
export class StartCode {
  /**
   * Retrieves the value of the start code from the query string.
   *
   * @returns {string|null} The value from the query string if found, null otherwise.
   */
  static getFromQueryString(): string | null {
    const query = new URLSearchParams(window.location.search);

    return query.get(QueryStringKey.StartCode);
  }

  /**
   * Adds the start code to the given URL as a query string.
   *
   * @param {string} path - The path to which the query string parameter should be added.
   * @param {string} startCode - The value of the query string parameter.
   * @return {string} - The updated URL with the query string parameter added.
   */
  static addToQueryString(path: string, startCode: string): string {
    const urlParts = path.split('#');
    let newPath = urlParts[0] ?? '';

    // Remove any existing start code
    newPath = newPath.replace(/sc=\w*/, '');

    newPath += newPath.includes('?') ? '&' : '?';
    newPath += `${QueryStringKey.StartCode}=${startCode}`;

    if (urlParts[1]) {
      newPath += `#${urlParts[1]}`;
    }

    return newPath;
  }
}
