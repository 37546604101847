import { Component, effect } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PresentationService } from '../../services/assessment/presentation.service';
import {
  AssessmentState,
  AssessmentStateService,
} from '../../services/assessment/assessment-state.service';
import { KeyboardKey } from '../../services/ui/keyboard.service';
import { HotkeyDirective } from '../../directives/hotkey.directive';
import { ProgressService } from '../../services/assessment/progress.service';
import { TimerService } from '../../services/assessment/timer.service';
import { VideoService } from '../../services/ui/video.service';

@Component({
  selector: 'cp-par-previous-button',
  standalone: true,
  imports: [CommonModule, HotkeyDirective],
  templateUrl: './previous-button.component.html',
})
export class PreviousButtonComponent {
  protected readonly KeyboardKey = KeyboardKey;
  private _videoDisablesButton: boolean = false;

  get showButton(): boolean {
    switch (this._state.currentState) {
      case AssessmentState.Complete:
      case AssessmentState.InProgress:
        return this.canGoBack();
      case AssessmentState.Support:
        return true;
      default:
        return false;
    }
  }

  get buttonText(): string {
    switch (this._state.currentState) {
      case AssessmentState.Complete:
      case AssessmentState.InProgress:
        return this._presentation.current.presentationItem
          .navigationPreviousLabel;
      case AssessmentState.Support:
        return 'Resume Assessment';
    }

    return '';
  }

  constructor(
    private readonly _presentation: PresentationService,
    private readonly _state: AssessmentStateService,
    private readonly _progress: ProgressService,
    private readonly _timer: TimerService,
    private readonly _videoService: VideoService,
  ) {
    effect(() => {
      this._videoDisablesButton =
        this._videoService.disablePreviousNavigation();
    });
  }

  previousClick(): void {
    switch (this._state.currentState) {
      case AssessmentState.Complete:
      case AssessmentState.InProgress:
        this._presentation.goToPrevious();
        break;
      case AssessmentState.Support:
        this._state.goBack();
        break;
    }
  }

  private canGoBack(): boolean {
    const current = this._presentation.current;
    const previous = current.previous;

    // If there is no previous item then there is nothing to go back to
    if (!previous.presentationItem) {
      return false;
    }

    const previousItemProgress = this._progress.getItemProgress(
      previous.presentationItem.questionId,
    );

    if (this._videoDisablesButton) {
      return false;
    }

    // If previous item exhausted views then we cannot go back
    if (
      previous.presentationItem.maxViewsPermitted > 0 &&
      previous.presentationItem.maxViewsPermitted <=
        previousItemProgress.shownToParticipantCount
    ) {
      return false;
    }

    // If are NOT about to change to another
    // content group then allow navigation.
    if (!current.isFirstInGroup) return true;

    // We are about to exit current content group - check that
    // we can enter the previous group if it exists
    if (!previous.contentGroup) return false;

    // If we are in complete group we cannot go anywhere
    if (current.contentGroup.isCompleteGroup) return false;

    const timer = this._timer.getTimerForGroup(previous.contentGroup.sourceId);

    return (
      previous.contentGroup.isReentryAllowed &&
      ((timer && !timer.isExpired) || !timer)
    );
  }
}
