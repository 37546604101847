import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * ClientMetricInterceptor is an HTTP interceptor that adds client screen size
 * to the headers of outgoing HTTP requests.
 */
export class ClientMetricInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const screenSize = `${document.documentElement.clientWidth}x${document.documentElement.clientHeight}`;

    req = req.clone({
      setHeaders: {
        'hm-client-screen-size': screenSize,
      },
    });

    return next.handle(req);
  }
}
