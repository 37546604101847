import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Random } from '@compass/helpers';

@Directive({
  selector: '[cpRandomId]',
  standalone: true,
})
export class RandomIdDirective implements OnInit {
  constructor(
    private readonly _renderer: Renderer2,
    private readonly _elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    if (!this._elementRef.nativeElement) return;

    this._renderer.setProperty(
      this._elementRef.nativeElement,
      'id',
      Random.id(),
    );
  }
}
