import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentGroup } from '../../../models/assessment/content-group';

@Component({
  selector: 'cp-par-debug-content-group-info',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './debug-content-group-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugContentGroupInfoComponent {
  @Input({ required: true })
  contentGroup!: ContentGroup;
}
