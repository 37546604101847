<div class="row align-items-center">
  <span
    class="col-12 col-md-7 text-truncate"
    *ngIf="presentation.current.contentGroup.label">
    {{ presentation.current.contentGroup.label }}
  </span>

  <div
    class="col-12 d-flex align-items-center justify-content-end"
    [ngClass]="{
      'col-md-5': presentation.current.contentGroup.label,
    }">
    <cp-par-progress-bar
      *ngIf="canShowProgress()"
      class="me-2"
      [progress]="progress.progressForCurrentGroup.progress" />

    <cp-par-timer
      class="mx-3"
      *ngIf="canShowTimer() && timer.timerForCurrentGroup"
      [elapsedSeconds]="timer.timerForCurrentGroup.numberOfSecondsElapsed"
      [totalSeconds]="timer.timerForCurrentGroup.durationSeconds" />

    <button
      *ngIf="
        state.currentState !== AssessmentState.Support &&
        state.currentState !== AssessmentState.Complete
      "
      class="btn btn-link support"
      title="help"
      (click)="state.setState(AssessmentState.Support)"
      [cpParHotkey]="{
        key: KeyboardKey.KeyH,
        req: { requireShift: true },
      }"
      triggerEvent="click">
      <cp-par-hotkey-text text="Help"></cp-par-hotkey-text>
    </button>
  </div>
</div>
