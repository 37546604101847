import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TimePipe } from '@compass/pipes';

@Component({
  selector: 'cp-par-timer',
  templateUrl: 'timer.component.html',
  styleUrls: ['timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TimePipe],
  standalone: true,
})
export class TimerComponent {
  @Input({ required: true })
  totalSeconds: number = 1;

  @Input({ required: true })
  elapsedSeconds: number = 0;

  get elapsedPercentage(): number {
    if (this.totalSeconds <= 0 || this.elapsedSeconds <= 0) {
      return 0;
    }

    if (this.elapsedSeconds > this.totalSeconds) {
      return 1;
    }

    return this.elapsedSeconds / this.totalSeconds;
  }
}
