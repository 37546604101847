import { Environment } from '@compass/environment';
import { AppConfig } from '../app-config';

export const appConfig: AppConfig = {
  environment: Environment.Staging,
  minLogLevel: 'debug',
  baseApiUrl: 'https://api.qa-asmt.app/',
  appName: 'Participant',
  reportUrl: 'https://report.qa-highmatch.com',
};
