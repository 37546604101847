import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ApplicationEnvironment,
  DebugService,
  DeviceInfoService,
} from '@compass/environment';
import { Title } from '@angular/platform-browser';
import {
  ContentGroupChangeArgs,
  PresentationService,
} from '../../shared/services/assessment/presentation.service';
import { AuthenticationService } from '../../shared/services/security/authentication.service';
import { SessionService } from '../../shared/services/security/session.service';
import { ProgressService } from '../../shared/services/assessment/progress.service';
import {
  AssessmentService,
  AssessmentExitReason,
} from '../../shared/services/assessment/assessment.service';
import { InactivityService } from '../../shared/services/ui/inactivity.service';
import { ModalReference, ModalService } from '@compass/notifications';
import { Logger } from '@compass/logging';
import { InactivityWarningModalComponent } from '../../shared/components/inactivity-warning-modal/inactivity-warning-modal.component';

@Component({
  selector: 'cp-par-authenticated',
  templateUrl: 'authenticated.component.html',
  styleUrls: ['authenticated.component.scss'],
})
export class AuthenticatedComponent implements OnInit, OnDestroy {
  private readonly _idleSub = this._inactivity.idle$.subscribe(
    this.onIdleStart.bind(this),
  );
  private readonly _timeoutSub = this._inactivity.timeout$.subscribe(
    this.onTimeout.bind(this),
  );

  private readonly _contentGroupChangeSub =
    this._presentation.contentGroupChange$.subscribe(
      this.contentGroupChange.bind(this),
    );

  private _timeoutModal?: ModalReference;

  protected initialized = false;

  constructor(
    protected readonly deviceInfo: DeviceInfoService,
    protected readonly debug: DebugService,
    protected readonly environment: ApplicationEnvironment,
    private readonly _title: Title,
    private readonly _presentation: PresentationService,
    private readonly _auth: AuthenticationService,
    private readonly _session: SessionService,
    private readonly _progress: ProgressService,
    private readonly _assessment: AssessmentService,
    private readonly _logger: Logger,
    private readonly _modal: ModalService,
    private readonly _inactivity: InactivityService,
  ) {
    if (!this.environment.isProduction) {
      this.debug.enable();
    }
  }

  async ngOnInit(): Promise<void> {
    this._session.start(this._auth.startCode, this._auth.sessionId);
    await this._assessment.initialize();

    this.contentGroupChange({
      newGroup: this._presentation.current.contentGroup,
    });

    this.initialized = true;

    this._inactivity.start();
  }

  ngOnDestroy(): void {
    this._contentGroupChangeSub.unsubscribe();
    this._idleSub.unsubscribe();
    this._timeoutSub.unsubscribe();

    this._progress.currentItemTracker.stopTracking();
    this._session.stop();

    this.debug.disable(false);

    this._auth.logOut();
  }

  protected toggleDebugWindow(): void {
    if (this.debug.windowVisible) {
      this.debug.hideDebugWindow();
    } else {
      this.debug.showDebugWindow();
    }
  }

  private async onIdleStart(): Promise<void> {
    this._logger.info('Detected user inactive - warning is shown.');

    this._timeoutModal = this._modal.error(
      InactivityWarningModalComponent,
      'Inactivity Warning',
      {
        confirmText: 'Return To Assessment',
        showCancel: this.debug.enabled,
        cancelText: 'DEBUG: Expire now',
      },
    );

    if (!(await this._timeoutModal.result)) {
      // Dialog cancellation should only occur when debug is enabled
      this._inactivity.timeout();
      return;
    }

    this._inactivity.start();

    this._logger.info(
      'User is active again - the warning window was dismissed.',
    );
  }

  private onTimeout(): void {
    this._logger.info(
      'The timeout period expired after inactivity warning was shown. The user will now be logged out.',
    );

    this._timeoutModal?.destroy();

    this._assessment.exit(AssessmentExitReason.InactivityTimeout);
  }

  private contentGroupChange(groupChangeArgs: ContentGroupChangeArgs): void {
    this._title.setTitle(groupChangeArgs.newGroup.label || 'Assessment');
  }
}
