import { Injectable } from '@angular/core';
import { AppConfig } from '../../../../app-config';
import { appConfig } from '../../../../environment/environment';

/**
 * Represents a service for retrieving application configuration values.
 */
@Injectable({
  providedIn: 'root',
})
export class AppConfigurationService {
  /**
   * Gets the application configuration values
   */
  get(): AppConfig {
    return appConfig;
  }
}
