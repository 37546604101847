<div class="d-inline-block" [ngbTooltip]="tooltipText">
  <button
    class="btn btn-success py-3 px-4"
    *ngIf="showButton"
    [disabled]="!enabled || isBusy"
    [cpParHotkey]="KeyboardKey.ArrowRight"
    (click)="nextClick()">
    <div class="spinner-border spinner-border-sm" *ngIf="isBusy"></div>
    <ng-container *ngIf="!isBusy">{{ buttonText }}</ng-container>
  </button>
</div>

<ng-template #supportFormSubmitted let-email>
  <p>Your request has been sent to the Assessment Support Team.</p>
  <p>
    We will respond to you at <strong>{{ email }}</strong> within one business
    day.
  </p>
  <p>
    You may close this browser window or click the button below to resume your
    assessment.
  </p>
</ng-template>
