<div
  cdkDrag
  cdkDragBoundary="body"
  (cdkDragEnded)="dragEnd($event)"
  [cdkDragFreeDragPosition]="currentPosition"
  [class.hidden]="!visible">
  <div class="debug-window-header" cdkDragHandle>
    <button (click)="hide()">&times;</button>
  </div>
  <ng-container *ngComponentOutlet="debugComponent" />
</div>
