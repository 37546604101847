<!-- @sonar-disable -->

<table class="table table-sm table-striped" role="presentation">
  <tbody>
    <!-- Object dump data -->
    <tr>
      <td colspan="2">
        <span class="fs-5 my-2 fw-bold">Item value dump</span>
      </td>
    </tr>
    <tr *ngFor="let item of $any(contentGroup) | keyvalue">
      <td>{{ item.key }}</td>
      <td>{{ item.value }}</td>
    </tr>
  </tbody>
</table>
