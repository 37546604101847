import { Component } from '@angular/core';

@Component({
  selector: 'cp-par-unauthenticated',
  template: '<router-outlet/>',
  styles: [
    `
      :host {
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    `,
  ],
})
export class UnauthenticatedComponent {}
