/**
 * Formats a name to identity capitalization rules
 * @param name first or last name of a person
 */
export function formatPersonName(name: string): string {
  if (!name) return '';

  // check if all caps
  if (name.toUpperCase() === name) {
    return capitalizeString(name);
  }

  // check if name contains at least one capital letter
  if (/[A-Z]/.test(name)) {
    return name.trim();
  }

  // If the above tests are false, create a capitalized string
  return capitalizeString(name);
}

/**
 * Capitalizes the first character and trims string
 * @param value string value to capitalize and trim
 */
function capitalizeString(value: string): string {
  return value
    .replace(
      /^(.)(.*)$/,
      (_, firstChar, restOfString) =>
        firstChar.toUpperCase() + restOfString.toLowerCase(),
    )
    .trim();
}
