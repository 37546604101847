<!-- @sonar-disable -->

<ng-container *ngIf="timer.timerForCurrentGroup">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-auto">
        <button
          class="btn"
          [ngClass]="{
            'btn-danger': timer.enabled,
            'btn-primary': !timer.enabled,
          }"
          (click)="toggleTimer()">
          {{ timer.enabled ? 'Disable' : 'Enable' }} timer
        </button>
      </div>
      <div class="col-auto">
        <button
          class="btn btn-primary"
          (click)="timer.timerForCurrentGroup.expire()">
          Expire
        </button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <table class="table table-sm table-striped" role="presentation">
          <tbody>
            <!-- Timer data -->
            <tr>
              <td>Timer ID</td>
              <td>{{ timer.timerForCurrentGroup.timerId }}</td>
            </tr>

            <tr>
              <td>Duration seconds</td>
              <td>{{ timer.timerForCurrentGroup.durationSeconds }}</td>
            </tr>

            <tr>
              <td>On expiration go to</td>
              <td>
                {{
                  presentation.current.contentGroup.timer
                    ?.onExpirationGoToContentGroupSourceId
                }}
              </td>
            </tr>

            <tr>
              <td>Seconds elapsed</td>
              <td>{{ timer.timerForCurrentGroup.numberOfSecondsElapsed }}</td>
            </tr>

            <tr>
              <td>Seconds remaining</td>
              <td>
                {{
                  timer.timerForCurrentGroup.durationSeconds -
                    timer.timerForCurrentGroup.numberOfSecondsElapsed
                }}
              </td>
            </tr>

            <tr>
              <td>Is running</td>
              <td>{{ timer.timerForCurrentGroup.isRunning }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!timer.timerForCurrentGroup">
  This content group does not have a timer
</ng-container>
