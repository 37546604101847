import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

/**
 * A component used for redirecting to a different page with query params.
 * When defining a route with this component provide data object and set
 * 'redirectTo' to the new path.
 *
 * E.g.
 *   {
 *     path: 'common/unsubscribe/:startKey',
 *     component: RedirectComponent,
 *     data: {
 *       redirectTo: 'unsubscribe/:startKey'
 *     }
 *   }
 *  The above will redirect 'common/unsubscribe' path to 'unsubscribe' with
 *  'startKey' parameter mapped onto the new route.
 * @class RedirectComponent
 */
@Component({
  selector: 'cp-redirect-component',
  template: '',
  standalone: true,
})
export class RedirectComponent implements OnInit {
  constructor(
    private readonly _router: Router,
    private readonly _route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const redirectTo = this._route.snapshot.data['redirectTo'];

    if (typeof redirectTo !== 'string') {
      this._router.navigate([]);
      return;
    }

    const urlCommands: string[] = [];

    for (const urlPart of redirectTo.split('/')) {
      // If it's just a string (not a parameter) then add it to the array
      if (!urlPart.startsWith(':')) {
        urlCommands.push(urlPart);
        continue;
      }

      const paramName = urlPart.replace(':', '');
      const paramValue = this._route.snapshot.params[paramName];

      // If the parameter is NOT found in the original URL then that is an invalid condition.
      // It's either not needed or this is a mistake. Throw an error - we must not continue.
      if (!paramValue) {
        throw new Error(
          `Redirect parameter [${paramName}] was specified, but it was not found in the original path. Either include it in the original URL or remove it from the redirect URL.`,
        );
      }

      urlCommands.push(paramValue);
    }

    this._router.navigate(urlCommands);
  }
}
