import { ComponentRef } from '@angular/core';
import { DialogOutletComponent } from '../components/dialog-outlet/dialog-outlet.component';

export class ModalReference {
  private _dialogResultResolve!: (result: boolean) => void;

  readonly result = new Promise<boolean>(
    resolve => (this._dialogResultResolve = resolve),
  );

  private constructor(
    private readonly _componentInstance: DialogOutletComponent,
  ) {
    _componentInstance.onDialogResolve(result => {
      this._dialogResultResolve(result);
    });
  }

  /**
   * Closes the modal with the specified result.
   *
   * @param {boolean} [result=true] - Indicates if the component should be confirmed (true) or cancelled (false).
   * @return {void}
   */
  close(result: boolean = true): void {
    if (result) {
      this._componentInstance.confirm();
    } else {
      this._componentInstance.cancel();
    }
  }

  /**
   *  Destroys the modal without any specific result.
   *  Any closing hooks will be disregarded.
   *
   *  @returns {void}
   */
  destroy(): void {
    this._componentInstance.destroy();
  }

  static from(
    componentRef: ComponentRef<DialogOutletComponent>,
  ): ModalReference {
    return new ModalReference(componentRef.instance);
  }
}
