<button
  class="btn btn-danger ms-2"
  [class.btn-danger]="progressStore.isTrackingEnabled"
  [class.btn-primary]="!progressStore.isTrackingEnabled"
  (click)="toggleProgressPersistence()">
  {{
    progressStore.isTrackingEnabled
      ? 'Disable progress persistence'
      : 'Enable progress persistence'
  }}
</button>

<button
  class="btn btn-danger ms-2"
  [class.btn-danger]="session.enabled"
  [class.btn-primary]="!session.enabled"
  (click)="toggleSessionTracking()">
  {{ session.enabled ? 'Disable session tracking' : 'Enable session tracking' }}
</button>
