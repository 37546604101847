// noinspection SuspiciousTypeOfGuard

import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
} from '@angular/core';
import { MathRenderer } from '../../helpers/math-renderer';

/**
 * Component that renders math equations
 */
@Component({
  selector: 'cp-math',
  template: '<ng-content></ng-content>',
  styleUrls: ['math.component.scss'],
  standalone: true,
})
export class MathComponent implements AfterViewInit {
  private readonly _mathRenderer = new MathRenderer();
  private _renderingInProgress: boolean = true;

  /**
   * Display mode for the component. Defaults to inline.
   */
  @Input()
  mode: 'inline' | 'block' = 'inline';

  @Input()
  disabled: boolean = false;

  /**
   * Indicates that the math rendering process is in progress
   */
  @HostBinding('class.rendering')
  get rendering(): boolean {
    return this._renderingInProgress;
  }

  constructor(private readonly _element: ElementRef) {}

  async ngAfterViewInit(): Promise<void> {
    await this._mathRenderer.render(this._element.nativeElement);
    this._renderingInProgress = false;
  }

  private isRenderingDisabled(): boolean {
    if (typeof this.disabled === 'boolean') return this.disabled;
    if (typeof this.disabled === 'string')
      return (<string>this.disabled).toLowerCase() === 'true';
    return true;
  }
}
