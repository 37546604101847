import { Subject } from 'rxjs';
import { Logger } from '@compass/logging';
import { Interval } from '@compass/helpers';

export class Timer {
  private readonly _expired = new Subject<void>();
  private readonly _started = new Subject<void>();
  private readonly _stopped = new Subject<void>();
  private readonly _interval = Interval.fromSeconds(
    this.timerTick.bind(this),
    1,
  );

  private _numberOfSecondsElapsed: number = 0;
  private _enabled: boolean = true;

  readonly expired$ = this._expired.asObservable();
  readonly started$ = this._started.asObservable();
  readonly stopped$ = this._stopped.asObservable();

  get numberOfSecondsElapsed(): number {
    return this._numberOfSecondsElapsed;
  }

  get isExpired(): boolean {
    return this.numberOfSecondsElapsed >= this.durationSeconds;
  }

  get isRunning(): boolean {
    return this._interval.isRunning;
  }

  get enabled(): boolean {
    return this._enabled;
  }

  set enabled(value: boolean) {
    this._enabled = value;

    if (!value) {
      this.stop();
    }
  }

  constructor(
    public readonly timerId: string,
    public readonly durationSeconds: number,
    numberOfSecondsElapsed: number = 0,
  ) {
    if (numberOfSecondsElapsed <= 0) return;

    this._numberOfSecondsElapsed =
      numberOfSecondsElapsed > durationSeconds
        ? durationSeconds
        : numberOfSecondsElapsed;
  }

  start(): void {
    if (!this.enabled || this.isRunning || this.isExpired) return;

    this._interval.start();
    this._started.next();

    Logger.instance.debug(`Timer [${this.timerId}] was started.`);
  }

  stop(): void {
    if (!this.isRunning) return;

    this._interval.stop();
    this._stopped.next();

    Logger.instance.debug(`Timer [${this.timerId}] was stopped.`);
  }

  expire(): void {
    this._numberOfSecondsElapsed = this.durationSeconds;

    this.timerTick();
  }

  private timerTick(): void {
    if (this.isExpired) {
      this.timerExpired();
      return;
    }

    this._numberOfSecondsElapsed++;
  }

  private timerExpired(): void {
    Logger.instance.debug(
      `Timer [${this.timerId}] expired and will be stopped.`,
    );

    this.stop();

    this._expired.next();
  }
}
