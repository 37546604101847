import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Regex } from '../../../helpers/src/regex';

/**
 * Validator that checks whether input value is a valid email address
 */
export function phoneValidator(
  control: AbstractControl,
): ValidationErrors | null {
  // Do not check for empty control - this should be handled by required attribute (validator)
  if (!control.value) return null;
  // If we pass test
  if (Regex.phone.test(control.value)) return null;

  // Test failed
  return {
    phone: true,
  };
}
