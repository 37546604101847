<div class="container">
  <div class="row my-2 align-items-center" *ngFor="let shortcut of shortcuts">
    <ng-container *ngIf="!shortcut.hidden?.()">
      <div class="col-5">{{ shortcut.title }}</div>
      <div class="col-7 text-end">
        <ng-container *ngFor="let key of shortcut.keys; let isLastKey = last">
          <kbd>{{ key }}</kbd>
          <span *ngIf="!isLastKey"> + </span>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
