<div class="container-fluid">
  <div class="row">
    <button
      class="col btn btn-primary"
      [disabled]="!presentation.current.previous.presentationItem"
      (click)="presentation.goToPrevious()">
      Previous item
    </button>
    <button
      class="col btn btn-primary ms-2"
      [disabled]="!presentation.current.next.presentationItem"
      (click)="presentation.goToNext()">
      Next item
    </button>

    <button
      class="col btn btn-primary ms-2"
      [disabled]="!presentation.current.previous.contentGroup"
      (click)="goToPreviousGroup()">
      Previous group
    </button>
    <button
      class="col btn btn-primary ms-2"
      [disabled]="!presentation.current.next.contentGroup"
      (click)="goToNextGroup()">
      Next group
    </button>
  </div>

  <div class="row mt-2">
    <select class="col form-select" #select>
      <option
        *ngFor="let group of content.contentGroups"
        [value]="group.sourceId">
        {{ group.label || 'N/A' }} ({{ group.sourceId }})
      </option>
    </select>

    <button
      class="col-auto btn btn-primary ms-2"
      (click)="presentation.goToGroup(select.value)">
      Go
    </button>
  </div>

  <div class="row mt-1">
    <div class="col">
      <small>
        <b>Note:</b> Navigating to a restricted item/group could result in
        incorrect rendering or server's refusal to accept and/or provide data.
      </small>
    </div>
  </div>
</div>
