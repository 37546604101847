import { Injectable, signal, WritableSignal } from '@angular/core';
import { PresentationService } from '../assessment/presentation.service';
import { ProgressService } from '../assessment/progress.service';

@Injectable({
  providedIn: 'root',
})
export class VideoService {
  public disableForwardNavigation: WritableSignal<boolean> = signal(false);
  public disablePreviousNavigation: WritableSignal<boolean> = signal(false);

  constructor(
    private readonly _presentationService: PresentationService,
    private readonly _progressService: ProgressService,
  ) {}

  public videoCompleted(moveNext: boolean = false): void {
    this.setState(false, false, false);

    if (moveNext) this._presentationService.goToNext();
  }

  public videoLoaded(): void {
    this._progressService.currentItemTracker.addView(); // updates view count
    this._progressService.updateProgress();
  }

  public init(
    disableForwardNavigation: boolean,
    disablePreviousNavigation: boolean,
    hideChoices: boolean,
  ): void {
    this.setState(
      disableForwardNavigation,
      disablePreviousNavigation,
      hideChoices,
    );
  }

  private setState(
    disableForwardNavigation: boolean,
    disablePreviousNavigation: boolean,
    hideChoices: boolean,
  ): void {
    this._presentationService.hideChoices.set(hideChoices);
    this.disableForwardNavigation.set(disableForwardNavigation);
    this.disablePreviousNavigation.set(disablePreviousNavigation);
  }
}
