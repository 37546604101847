import { Directive, ElementRef, HostListener } from '@angular/core';

/**
 * A directive used for communication with an iframe content.
 * This directive should be applied to the iframe element using the [cpIframeCommunicator] attribute.
 *
 */
@Directive({
  selector: 'iframe[cpIframeCommunicator]',
  standalone: true,
})
export class IframeRelayDirective {
  private readonly _beforeLoadMessageBuffer: unknown[] = [];
  private readonly _iframe: HTMLIFrameElement;

  private _frameLoaded = false;

  constructor(elementRef: ElementRef) {
    if (!(elementRef.nativeElement instanceof HTMLIFrameElement)) {
      throw new Error(
        'You can only use [cpIframeCommunicator] directive on IFrame element.',
      );
    }

    this._iframe = elementRef.nativeElement;
  }

  /**
   * Sends data via to the iframe window using postMessage.
   *
   * @param {unknown} data - The data to be sent.
   *
   * @return {void}
   */
  send(data: unknown): void {
    if (!this._frameLoaded) {
      this._beforeLoadMessageBuffer.push(data);
      return;
    }

    this._iframe.contentWindow?.postMessage(data, this._iframe.src);
  }

  @HostListener('load')
  protected onLoad(): void {
    this._frameLoaded = true;

    this.processBeforeLoadBuffer();
  }

  private processBeforeLoadBuffer(): void {
    for (const chunk of this._beforeLoadMessageBuffer) {
      this.send(chunk);
    }

    this._beforeLoadMessageBuffer.splice(0);
  }
}
