import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupProgress } from '../../../services/assessment/progress.service';

@Component({
  selector: 'cp-par-debug-progress',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './debug-progress.component.html',
})
export class DebugProgressComponent {
  @Input({ required: true })
  groupProgress!: GroupProgress;
}
