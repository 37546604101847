import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

// This directive was influenced by Ronnain and Josef on StackOverflow.
@Directive({
  selector: '[cpSwipe]',
  standalone: true,
})
export class SwipeDirective {
  @Output()
  readonly swipeEnd = new EventEmitter<boolean>();

  swipeCoord = [0, 0];
  swipeTime = new Date().getTime();

  @HostListener('touchstart', ['$event'])
  onSwipeStart($event: TouchEvent): void {
    this.onSwipe($event, true);
  }

  @HostListener('touchend', ['$event'])
  onSwipeEnd($event: TouchEvent): void {
    this.onSwipe($event, false);
  }

  onSwipe(e: TouchEvent, start: boolean): void {
    this.swipe(e, start);
  }

  swipe(e: TouchEvent, start: boolean): void {
    const coord: [number, number] = [
      e.changedTouches[0].clientX,
      e.changedTouches[0].clientY,
    ];
    const time = new Date().getTime();

    if (start) {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else {
      const direction = [
        coord[0] - this.swipeCoord[0],
        coord[1] - this.swipeCoord[1],
      ];
      const duration = time - this.swipeTime;

      if (
        duration < 1000 && //
        Math.abs(direction[0]) > 30 && // Long enough
        Math.abs(direction[0]) > Math.abs(direction[1] * 3)
      ) {
        // Horizontal enough
        this.swipeEnd.emit(true);
      }
    }
  }
}
